export const nodeDelete = (projectTree, deletedNode) => {
  deleteNodeById(projectTree, deletedNode.id);

  return projectTree;
};

function deleteNodeById(nodes, idToDelete) {
  for (let i = 0; i < nodes.length; i += 1) {
    const node = nodes[i];

    if (node.id === idToDelete) {
      nodes.splice(i, 1);
      for (let j = i + 1; j < nodes.length; j += 1) {
        nodes[j].referencePosition -= 1;
      }
      return true;
    }

    if (node.nodes && node.nodes.length > 0) {
      const found = deleteNodeById(node.nodes, idToDelete);
      if (found) {
        return true;
      }
    }
  }

  return false;
}
