import { useDrag, useDrop } from "react-dnd";
import { useEffect, useState } from "react";
import { getEmptyImage } from "react-dnd-html5-backend";
import { useMutation } from "urql";
import { ConfigMoveMut } from "api/mutations/configurations";
import { NodeUpdateMut } from "api/mutations";

export const useProjectTreeDragDrop = ({
  id,
  kind,
  title,
  isOpen,
  nodes,
  configurations,
  parentId,
  referencePosition,
  isWizard,
  exeGetProjectTreeDebounced,
  isParentDragged,
  dropRelations,
}) => {
  const [isDropInside, setIsDropInside] = useState(false);
  const [positionToFolder, setPositionToFolder] = useState(null);

  const [_updateConfData, exeMoveConfiguration] = useMutation(ConfigMoveMut);
  const [_updateNodeData, exeUpdateNode] = useMutation(NodeUpdateMut);

  const [{ isDragging }, dragRef, preview] = useDrag(
    () => ({
      type: "box",
      item: {
        id,
        title,
        isOpen,
        nodes,
        configurations,
        parentId,
        kind,
        referencePosition,
      },
      collect: (monitor) => ({
        isDragging: monitor.isDragging(),
      }),

      canDrag: () => {
        if (document.activeElement.tagName.toLowerCase() === "input") {
          return false;
        }

        return true;
      },
    }),
    [isOpen, id, title, parentId, referencePosition],
  );

  const [{ isOverCurrent, clientOffset, canDrop }, dropRef] = useDrop(
    () => ({
      accept: "box",
      canDrop: (item) => {
        const cannotDrop = [
          "wizardRoomcontrol",
          "system_controller",
          "fam",
          "wizardFireprotection",
        ];

        const noWizardNodeDrop = cannotDrop.includes(item.kind) && !isWizard;
        if (noWizardNodeDrop || isWizard) {
          return false;
        }
        return true;
      },
      drop: (item, monitor) => {
        const didDropInSamePlace = monitor.didDrop();

        if (!didDropInSamePlace && !isParentDragged && item.id !== id) {
          if (item.product) {
            exeMoveConfiguration({
              id: item.id,
              destinationNodeId: isDropInside ? id : parentId || id,
            }).then(({ data }) => {
              const movedConf = data?.moveProjectNodeConfiguration;
              if (movedConf) {
                exeGetProjectTreeDebounced();
              }
            });
          } else {
            // TODO: AB doesn't let us to move upper sibling below beneath sibling
            const isParentExist = !!parentId === !!item.parentId;
            const isSameParent = parentId === item.parentId;

            const isHigherPosition = referencePosition > item.referencePosition;

            let nextPos =
              isParentExist && isSameParent && isHigherPosition
                ? referencePosition
                : referencePosition + 1;

            if (positionToFolder === dropRelations.before) {
              nextPos -= 1;
            }
            const isDropIntoParent = item.parentId === id;
            const dropInsideReferencePosition = isDropIntoParent
              ? nodes.length
              : nodes.length + 1;

            const variables = {
              id: item.id,
              referencePosition: isDropInside
                ? dropInsideReferencePosition
                : nextPos,
              input: { parentId: isDropInside ? id : parentId || null },
            };

            exeUpdateNode(variables, {
              projectPauseExchangeType: "nodeUpdate",
            });
          }
        }

        setIsDropInside(false);
      },
      collect: (monitor) => ({
        isOverCurrent: monitor.isOver({ shallow: true }),
        clientOffset: monitor.getClientOffset()?.y,
        canDrop: monitor.canDrop(),
      }),
    }),
    [isDropInside, referencePosition, nodes, parentId, positionToFolder],
  );

  useEffect(() => {
    preview(getEmptyImage(), { captureDraggingState: true });
  }, [preview]);

  return {
    dragRef,
    dropRef,
    isDragging,
    isOverCurrent,
    clientOffset,
    isDropInside,
    setIsDropInside,
    positionToFolder,
    setPositionToFolder,
    isParentDragged,
    canDrop,
  };
};
