import React, { useCallback, useMemo } from "react";
import * as Scrivito from "scrivito";
import { Select } from "components/Select/Select";
import { resultsNumberOptions } from "variables";
import { translate } from "utils";
import cx from "classnames";

export const ItemsPerPageSelect = Scrivito.connect(
  ({ itemsPerPage, onChange, className }) => {
    const handleSelectChange = useCallback(
      (selectedOption) => {
        onChange(Number(selectedOption.value));
      },
      [onChange],
    );

    const selectedOption = useMemo(
      () =>
        resultsNumberOptions.find((option) => option.value === itemsPerPage),
      [itemsPerPage],
    );

    const currentPageObjClass = useMemo(
      () => Scrivito.currentPage().objClass(),
      [],
    );

    const labelText = useMemo(
      () =>
        currentPageObjClass === "ProjectList"
          ? translate("PROJECTS_PER_PAGE")
          : translate("ITEMS_PER_PAGE"),
      [currentPageObjClass],
    );

    return (
      <div
        className={cx(
          "d-flex font-md align-items-center gap-1 text-gray-700",
          className,
        )}
      >
        <div>{labelText}</div>
        <Select
          options={resultsNumberOptions}
          onChange={handleSelectChange}
          value={selectedOption}
          name="itemsPerPage"
          className="font-md"
        />
      </div>
    );
  },
);
