import * as React from "react";
import { useCallback, useMemo } from "react";
import * as Scrivito from "scrivito";
import { renderProjectsShowing } from "utils/renderProjectsShowing";
import { ProductListTable } from "./SubComponents/ProductListTable";
import { TablePagination } from "../TablePagination";

export const ProductListOfProject = Scrivito.connect(
  ({
    currentPageNo,
    fetchingProductList,
    fetchingPrices,
    commissionFetching,
    isOrderDescending,
    pagesCount,
    products,
    productsCount,
    setCurrentPageNo,
    setIsOrderDescending,
    tableRef,
    commissioningProduct,
    itemsPerPage,
  }) => {
    const skeletonCount = useMemo(
      () =>
        +currentPageNo !== +pagesCount
          ? itemsPerPage
          : productsCount - itemsPerPage * (currentPageNo - 1),
      [currentPageNo, pagesCount, productsCount, itemsPerPage],
    );

    const isLastPage = useMemo(
      () => currentPageNo === pagesCount,
      [currentPageNo, pagesCount],
    );

    const handleSetCurrentPage = useCallback(
      (pageToSet) => {
        setCurrentPageNo(
          pageToSet > pagesCount ? pageToSet - 1 : pageToSet || 1,
        );
      },
      [setCurrentPageNo, pagesCount],
    );

    return (
      <>
        <ProductListTable
          products={products}
          isOrderDescending={isOrderDescending}
          setIsOrderDescending={setIsOrderDescending}
          skeletonCount={skeletonCount}
          fetching={fetchingProductList}
          fetchingPrices={fetchingPrices}
          commissionFetching={commissionFetching}
          tableRef={tableRef}
          isLastPage={isLastPage}
          commissioningProduct={commissioningProduct}
        />
        <div className="position-relative d-flex justify-content-center w-100 pt-1_5 pb-2_5 text-gray-700">
          <div className="position-absolute top-0 start-0 text-gray-400">
            {renderProjectsShowing(currentPageNo, productsCount, itemsPerPage)}
          </div>
          <TablePagination
            pagesCount={pagesCount}
            currentPage={currentPageNo}
            onChangePage={handleSetCurrentPage}
          />
        </div>
      </>
    );
  },
);
