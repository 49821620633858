import React, { useState, useEffect } from "react";
import { formatBytesToMB, handleOpenManual, translate } from "utils";

export const AccordionItemFileDescription = ({
  fileDescription,
  contentLength,
  extension,
  contentUrl,
}) => {
  const [scrivitoDocumentContentLength, setScrivitoDocumentContentLength] =
    useState(null);
  const [scrivitoDocumentContentUrl, setScrivitoDocumentContentUrl] =
    useState(null);

  useEffect(() => {
    const fetchData = async () => {
      const [length, url] = await Promise.all([contentLength, contentUrl]);
      setScrivitoDocumentContentLength(length);
      setScrivitoDocumentContentUrl(url);
    };

    fetchData();
  }, [contentLength, contentUrl]);

  const formattedFileSize =
    scrivitoDocumentContentLength &&
    formatBytesToMB(scrivitoDocumentContentLength);

  const documentDescription = scrivitoDocumentContentLength
    ? `${fileDescription} | ${formattedFileSize} | ${extension}`
    : `${fileDescription} | ${extension}`;

  return (
    fileDescription && (
      <div
        className="d-flex align-items-center gap-1 text-gray-400"
        style={{ fontSize: "14px", fontFamily: "HelveticaNeueW01-57Cn" }}
      >
        <div>{documentDescription}</div>
        {contentUrl && (
          <div
            className="text-blue-300 cursor-pointer"
            onClick={() => handleOpenManual(scrivitoDocumentContentUrl)}
          >
            {translate("OPEN_PDF")}
          </div>
        )}
      </div>
    )
  );
};
