import * as React from "react";
import * as Scrivito from "scrivito";
import { getPageLink } from "utils";

export const Logo = Scrivito.connect(
  ({ homepage, linkClass, imgClass, isConfigLog }) => {
    if (
      !homepage ||
      !(homepage.get("logo") || homepage.get("configuratorLogo"))
    ) {
      return null;
    }
    const logo = isConfigLog
      ? homepage.get("configuratorLogo")
      : homepage.get("logo");

    const logoLink = homepage.get("logoLink");

    return (
      <Scrivito.LinkTag
        to={logoLink || getPageLink(homepage)}
        className={linkClass}
      >
        <Scrivito.ImageTag
          content={logo}
          className={imgClass}
          alt="TROX - The art of handling air"
          style={{ minWidth: "126px" }}
        />
      </Scrivito.LinkTag>
    );
  },
);
