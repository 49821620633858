import * as React from "react";
import * as Scrivito from "scrivito";
import cx from "classnames";
import { translate } from "utils";
import { usePageType } from "hooks";

import s from "./LeftSidebar.module.scss";
import { Icon } from "../Icon/Icon";

export const LeftSidebarButton = Scrivito.connect(
  ({ activeButton, currentPageButton, item = {}, setActiveButton }) => {
    const activeButtonTitle = activeButton?.title;
    const currentPageButtonTitle = currentPageButton?.title;

    const { iconName, title, prefix: iconPrefix, refProp } = item;

    const pageType = usePageType();
    const isPageFromActiveButton =
      activeButtonTitle === title &&
      activeButton?.pageTypes?.includes(pageType);

    const handleBtnClick = () => {
      setActiveButton((prev) => {
        const activeButtonWithFirstLevel = {
          ...item,
          subNavLevel: { value: 0 },
        };
        if (prev?.title === title) {
          if (prev?.subNavLevel?.value > 0) {
            return activeButtonWithFirstLevel;
          }
          return null;
        }
        return activeButtonWithFirstLevel;
      });
    };

    if (refProp === "configLeftSidebarChangeLogRefs") {
      const changeLog = Scrivito.Obj.getByPermalink("changelog");

      return (
        <Scrivito.LinkTag
          data-testid={`LeftSidebarButton_${iconName}-link`}
          target="_blank"
          to={changeLog}
          className={cx(
            "p-0 border-0 d-flex flex-column align-items-center justify-content-center" +
              " cursor-pointer flex-shrink-0",
            s.leftSidebarButton,
          )}
        >
          <Icon
            iconName={iconName}
            prefix={iconPrefix}
            className="icon-40 mb-1"
          />
        </Scrivito.LinkTag>
      );
    }

    return (
      <div
        className={cx(
          "p-0 border-0 d-flex flex-column align-items-center justify-content-center" +
            " cursor-pointer flex-shrink-0",
          s.leftSidebarButton,
          {
            [s.leftSidebarButtonFocused]:
              activeButtonTitle === title || isPageFromActiveButton,
            [s.leftSidebarButtonActive]: currentPageButtonTitle === title,
          },
        )}
        key={title}
        onClick={handleBtnClick}
      >
        <Icon
          iconName={iconName}
          prefix={iconPrefix}
          className="icon-40 mb-1"
        />
        <span className="text-uppercase mb-0 fw-bolder">
          {translate(title)}
        </span>
      </div>
    );
  },
);
