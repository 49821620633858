import React, { useMemo } from "react";
import * as Scrivito from "scrivito";
import { priceToString } from "utils/priceToString";
import { translate } from "utils";

export const ProductListTableRow = Scrivito.connect(
  ({ product, fetchingPrices }) => {
    const {
      title,
      presentation,
      quantity,
      unitPrice,
      totalPrice = null,
      priceGroup = null,
    } = product;

    const { unitPriceString, totalPriceString } = useMemo(
      () => ({
        unitPriceString: unitPrice
          ? priceToString({
              price: unitPrice,
              noPriceText: translate("NO_PRICE"),
            })
          : null,
        totalPriceString: totalPrice
          ? priceToString({
              price: totalPrice,
              noPriceText: translate("NO_PRICE"),
            })
          : null,
      }),
      [unitPrice, totalPrice],
    );

    return (
      <tr>
        <td>
          <div className="text-gray-700 fw-bold">{title}</div>
        </td>
        <td>{presentation}</td>
        <td>{quantity}</td>
        {fetchingPrices ? (
          <>
            <td>
              <div className="blockSkeleton d-flex justify-content-center align-items-center w-100"></div>
            </td>
            <td>
              <div className="blockSkeleton d-flex justify-content-center align-items-center w-100"></div>
            </td>
            <td>
              <div className="blockSkeleton d-flex justify-content-center align-items-center w-100"></div>
            </td>
          </>
        ) : (
          <>
            <td>{unitPriceString}</td>
            <td>{totalPriceString}</td>
            <td>{priceGroup}</td>
          </>
        )}
      </tr>
    );
  },
);
