import React from "react";
import * as Scrivito from "scrivito";
import { UncontrolledTooltip } from "reactstrap";

import { TranslateWrapper } from "components/TranslateWrapper";
import {
  translate,
  handleSingleManualDownload,
  handleOpenManual,
  formatBytesToMB,
} from "utils";
import { Button } from "components/Button";

export const ManualsDownloadItem = Scrivito.connect(({ manualDocument }) => {
  const manualUrl = manualDocument.contentUrl();
  const manualTitle = manualDocument.get("title");
  const manualSize = formatBytesToMB(manualDocument.contentLength());
  const manualFormat = manualDocument.get("fileTypeDe");

  const infoText = `${manualDocument.get("title")} | Installation manual |
   ${manualSize} | ${manualFormat} `;

  return (
    <div className="d-flex justify-content-between text-gray-700">
      <div className="d-flex gap-2 align-items-center">
        <>
          <Button
            data-testid="downloadIcon"
            square={true}
            color="tertiary"
            iconPrefix="trox"
            icon="arrow-down-bracket-regular"
            iconClassName="icon-16"
            classNames="border-0 cursor-pointer"
            id="downloadIcon"
            onClick={() => handleSingleManualDownload(manualDocument)}
          />
          <UncontrolledTooltip target="downloadIcon" placement="top">
            {translate("DOWNLOAD")}
          </UncontrolledTooltip>
          {typeof translate("DOWNLOAD") === "object" && (
            <TranslateWrapper
              tooltipId="downloadIcon"
              tooltipText={translate("DOWNLOAD").props.tooltipText}
              placement="bottom"
            />
          )}
        </>
        <div>{manualTitle}</div>
      </div>
      <div className="d-flex gap-2_5 align-items-center">
        <div className="text-gray-400">{infoText}</div>
        <div
          className="text-blue-300 cursor-pointer"
          onClick={() => handleOpenManual(manualUrl)}
        >
          {translate("OPEN_PDF")}
        </div>
      </div>
    </div>
  );
});
