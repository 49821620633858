const findNodeById = ({ nodes, parentId }) => {
  for (const node of nodes) {
    if (node.id === parentId || parentId === null) {
      return node;
    }
    if (node.nodes?.length) {
      const found = findNodeById({ nodes: node.nodes, parentId });
      if (found) {
        return found;
      }
    }
  }
  return null;
};

const addNodeToParent = ({ parentNode, newNode, referencePosition = null }) => {
  parentNode.nodes = parentNode.nodes || [];

  if (referencePosition !== null) {
    parentNode.nodes.forEach((node) => {
      if (node.referencePosition >= referencePosition) {
        node.referencePosition += 1;
      }
    });
    newNode.referencePosition = referencePosition;
    parentNode.nodes.splice(referencePosition - 1, 0, newNode);
  } else {
    newNode.referencePosition = parentNode.nodes.length + 1;
    parentNode.nodes.push(newNode);
  }
};

export const addNodeByParentId = ({
  nodes,
  parentId,
  newNode,
  referencePosition = null,
}) => {
  const parentNode = findNodeById({ nodes, parentId });

  if (!parentNode) {
    console.error(`Parent node with ID ${parentId} not found.`);
    return false; // Родительский узел не найден
  }

  addNodeToParent({ parentNode, newNode, referencePosition });
  return true;
};

export const updateNodeIds = ({ nodes, parentId = "root" }) => {
  nodes.forEach((node) => {
    node.id = `temp__${parentId}__${node.referencePosition}`;
    node.parentId = parentId;
    if (node.nodes && node.nodes.length > 0) {
      updateNodeIds({ nodes: node.nodes, parentId: node.id });
    }
  });
};

export const addConfigurationToNode = ({
  nodes,
  parentId,
  newConfiguration,
}) => {
  const parentNode = findNodeById({ nodes, parentId });

  if (!parentNode) {
    console.error(`Node with ID ${parentId} not found.`);
    return false;
  }
  parentNode.configurations = parentNode.configurations || [];
  parentNode.configurations.push(newConfiguration);
  return true;
};
