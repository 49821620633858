import {
  addNodeByParentId,
  updateNodeIds,
} from "api/cacheUtils/treeNodeUpdates/utils";

export const nodeCopy = (projectTree, result, _args, info) => {
  let newNode;
  if (info?.optimistic) {
    newNode = JSON.parse(JSON.stringify(result));
  } else {
    newNode = result;
  }
  const { parentId } = newNode;

  if (newNode.id.startsWith("temp__")) {
    if (newNode.nodes?.length > 0) {
      updateNodeIds({ nodes: newNode.nodes, parentId: newNode.id });
    }
    if (newNode.configurations?.length > 0) {
      newNode.configurations = newNode.configurations.map((configuration) => ({
        ...configuration,
        id: `temp__${configuration.id}`,
      }));
    }
  }

  if (parentId === null) {
    projectTree[0].nodes.push(newNode);
    return projectTree;
  }

  addNodeByParentId({ nodes: projectTree, parentId, newNode });
  return projectTree;
};
