import { useState } from "react";

export const useItemsPerPage = (initialItemsPerPage) => {
  const [itemsPerPage, setItemsPerPage] = useState(initialItemsPerPage);
  const [isItemsPerPageChanged, setIsItemsPerPageChanged] = useState(false);
  const [currentPageNo, setCurrentPageNo] = useState(1);

  const handleItemsPerPageChange = (value) => {
    setItemsPerPage(value);
    setIsItemsPerPageChanged(true);
    setCurrentPageNo(1);
  };

  return {
    itemsPerPage,
    isItemsPerPageChanged,
    handleItemsPerPageChange,
    setIsItemsPerPageChanged,
    setItemsPerPage,
    currentPageNo,
    setCurrentPageNo,
  };
};
