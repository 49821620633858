import React, { useCallback, useMemo } from "react";
import * as Scrivito from "scrivito";
import { ModalHeader } from "reactstrap";
import NiceModal from "@ebay/nice-modal-react";
import { useMutation } from "urql";

import { useUserStatuses } from "hooks";
import { useUserRole } from "hooks/useUserRole";
import { useUserGroup } from "hooks/useUserGroup";
import { translate } from "utils";

import { useAppBaseContext } from "providers";
import { UserDetailsForm } from "./UserDetailsForm";
import { ModalWrapper } from "../../ModalWrapper";

export const UserDetailsModal = Scrivito.connect(() =>
  NiceModal.create(({ project = {}, mutation, resetCallback = () => {} }) => {
    const { isSameProj } = useAppBaseContext();
    const modal = NiceModal.useModal();
    const userStatuses = useUserStatuses();
    const { lang } = useAppBaseContext();

    const [{ fetching, stale }, execute] = useMutation(mutation);

    const optionsList = useMemo(
      () =>
        userStatuses?.map((item) => ({
          value: item.status,
          label: translate(item.status),
          color: item.color,
        })),
      [userStatuses],
    );

    const userRole = useUserRole();
    const userGroup = useUserGroup();

    const rolesOptions = useMemo(
      () =>
        isSameProj
          ? userRole?.map(({ value, label }) => ({
              value,
              label,
            }))
          : "",
      [userRole, isSameProj],
    );

    const group = useMemo(
      () =>
        isSameProj
          ? userGroup?.map(({ value, label }) => ({
              value,
              label,
            }))
          : "",
      [userGroup, isSameProj],
    );

    const initialValues = useMemo(
      () => ({
        title: project.title || "",
        constructionTitle: project.constructionTitle || "",
        projectNumber: project.projectNumber || "",
        description: project.description || "",
        status: optionsList?.find(
          (item) => item.value === (project.status || "draft"),
        ),
        rolesOptions: { value: "User", label: "User" },
      }),
      [
        optionsList,
        project.constructionTitle,
        project.description,
        project.projectNumber,
        project.status,
        project.title,
      ],
    );

    // const validationSchema = useMemo(
    //   () =>
    //     Yup.object({
    //       title: Yup.string()
    //         .trim("The contact name cannot include leading and trailing spaces")
    //         .min(4, translate("PROJECT_NAME_VALIDATION_SHORT"))
    //         .required(translate("PROJECT_NAME_VALIDATION_EMPTY"))
    //         .test("max-length", function validateTitleLength(value) {
    //           return (
    //             value.length <= MAX_PROJECT_TITLE_LENGTH ||
    //             this.createError({
    //               message: `${translate("PROJECT_NAME_VALIDATION_LONG")} ${
    //                 value.length
    //               }`,
    //               path: "title",
    //               value,
    //             })
    //           );
    //         }),
    //         group: Yup.object()
    //         .typeError(" ")
    //         .nullable()
    //         .required(translate("PROJECT_LOCATION_SELECT_VALIDATION_EMPTY")),
    //       units: Yup.object()
    //         .typeError(" ")
    //         .nullable()
    //         .required(translate("PROJECT_UNITS_VALIDATION_EMPTY")),
    //     }),
    //   [],
    // );

    const handleSubmit = useCallback(
      (values) => {
        if (!fetching) {
          execute(
            project?.id
              ? {
                  id: project.id,
                  input: {
                    ...values,
                    status: values.status?.value || userStatuses?.[0]?.status,
                    group: values.group?.value,
                    units: values.units?.value,
                  },
                }
              : {
                  ...values,
                  status: values.status?.value || userStatuses[0]?.status,
                  group: values.group?.value,
                  units: values.units?.value,
                  language: lang,
                },
          ).then(({ data, error }) => {
            if (!error) {
              if (!project.id) {
                modal.resolve({ ...data.createProject });
              }
              modal.remove();
            }
          });
          resetCallback();
        }
      },
      [fetching, execute, project.id, userStatuses, lang, resetCallback, modal],
    );

    return (
      <ModalWrapper
        isOpen={modal.visible}
        centered={true}
        size="lg"
        toggle={modal.hide}
        isLoading={fetching || stale}
      >
        <ModalHeader
          toggle={modal.hide}
          tag="div"
          className="d-flex align-items-start"
        >
          <h3 className="text-uppercase mb-1">CEEATE NEW USER</h3>
          <h4 className="mb-2_5">Please Define the User Properties</h4>
        </ModalHeader>
        <UserDetailsForm
          fetching={fetching}
          handleSubmit={handleSubmit}
          initialValues={initialValues}
          isUpdate={!!project.id}
          countriesOptions={rolesOptions}
          optionsList={optionsList}
          projectStatuses={userStatuses}
          group={group}
        />
      </ModalWrapper>
    );
  }),
);
