import React from "react";
import { translate } from "utils";
import { useFetchManualsForProduct } from "hooks";
import { ManualsDownloadItem } from "components/_ProductConfigurator/SubComponents/Downloads/ManualsDownloadItem";
import { ProjectDownloadAccordionItem } from "./ProjectDownloadAccordionItem";

export const ScrivitoDocumentsDownloadItem = ({
  item,
  seriesId,
  data,
  toggle,
  setData,
  open,
}) => {
  const { documents } = useFetchManualsForProduct(seriesId);

  const isCertificates = item.title === "PRODUCT_CONFIGURATOR_CERTIFICATE";
  const isManuals = item.title === "PRODUCT_CONFIGURATOR_MANUALS";

  let documentList = [];
  if (isCertificates) {
    documentList = documents.certificates;
  } else if (isManuals) {
    documentList = documents.manuals;
  }

  const hasDocuments = documentList.length > 0;

  return (
    <ProjectDownloadAccordionItem
      open={open}
      title={item.title}
      key={item.title}
      toggle={toggle}
      checked={item.checked}
      setItemsData={setData}
      seriesId={seriesId}
      hasContent={true}
      itemsData={data}
    >
      {hasDocuments ? (
        documentList.map((obj, index) => (
          <ManualsDownloadItem key={index} manualDocument={obj} />
        ))
      ) : (
        <div className="text-gray-400">
          {translate("NO_DOCUMENTS_AVAILABLE")}
        </div>
      )}
    </ProjectDownloadAccordionItem>
  );
};
