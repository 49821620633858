import React, { useCallback } from "react";
import * as Scrivito from "scrivito";
import { AccordionBody, AccordionHeader, AccordionItem } from "reactstrap";
import cx from "classnames";
import { useFetchManualsForProduct } from "hooks";
import { Icon } from "components/Icon/Icon";
import { Checkbox } from "components/Inputs";
import { CheckboxAccordionContent } from "./CheckboxAccordionContent";
import { AccordionItemFileDescription } from "./AccordionItemFileDescription";

export const ProjectDownloadAccordionItem = Scrivito.connect(
  ({
    title,
    toggle,
    children,
    checked = false,
    setItemsData,
    seriesId,
    itemsData,
    hasContent,
    fileDescription,
    contentLength,
    extension,
    contentUrl,
  }) => {
    const { documents } = useFetchManualsForProduct(seriesId);

    const handleCheckboxClick = useCallback(() => {
      setItemsData((prevData) =>
        prevData?.map((item) => {
          if (item.title === title) {
            if (!item.content) {
              return { ...item, checked: !item.checked };
            }
            return {
              ...item,
              checked: !item.checked,
              content: item.content.map((subItem) => ({
                ...subItem,
                checked: !item.checked,
              })),
            };
          }

          if (item.content) {
            const updatedSublist = item.content.map((subItem) => ({
              ...subItem,
              checked:
                subItem.title === title ? !subItem.checked : subItem.checked,
            }));

            const isAnySubItemChecked = updatedSublist.some(
              (subItem) => subItem.checked,
            );

            return {
              ...item,
              content: updatedSublist,
              checked: isAnySubItemChecked,
            };
          }

          return item;
        }),
      );
    }, [title, setItemsData]);

    return (
      <AccordionItem
        className={cx({
          "border-bottom border-bottom-1 border-bottom-solid": hasContent,
        })}
      >
        <AccordionHeader
          tag="h6"
          className={cx({
            "p-0": !hasContent,
            "p-2_5 mb-0": hasContent,
          })}
          targetId={title}
        >
          <div
            className={cx(
              "gap-2 font-md text-gray-700 d-flex align-items-center",
              {
                "cursor-not-allowed pe-none opacity-50":
                  (title === "PRODUCT_CONFIGURATOR_MANUALS" &&
                    (!documents || documents.manuals.length === 0)) ||
                  (title === "PRODUCT_CONFIGURATOR_CERTIFICATE" &&
                    (!documents || documents.certificates.length === 0)),
              },
            )}
          >
            <Checkbox
              id={title}
              checked={checked}
              data-testid={`checkbox-on-${title}`}
              onChange={handleCheckboxClick}
            />
            <CheckboxAccordionContent
              title={title}
              itemsData={itemsData}
              hasContent={hasContent}
            />
          </div>

          {hasContent ? (
            <Icon
              prefix="trox"
              iconName="chevron-down-light"
              onClick={() => toggle(title)}
            />
          ) : (
            <AccordionItemFileDescription
              fileDescription={fileDescription}
              contentLength={contentLength}
              extension={extension}
              contentUrl={contentUrl}
            />
          )}
        </AccordionHeader>
        <AccordionBody accordionId={title}>
          <div className="px-5">{children}</div>
        </AccordionBody>
      </AccordionItem>
    );
  },
);
