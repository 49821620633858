// Project tree node manipulations
import { get, set } from "lodash-es";
import { ProjectDataQry } from "api/queries";
import { nodeCreate } from "api/cacheUtils/treeNodeUpdates/nodeCreate";
import { nodeDelete } from "api/cacheUtils/treeNodeUpdates/nodeDelete";
import { nodeCopy } from "api/cacheUtils/treeNodeUpdates/nodeCopy";
import { nodeUpdate } from "api/cacheUtils/treeNodeUpdates/nodeUpdate";
import { addConfig } from "api/cacheUtils/treeNodeUpdates/addConfig";

const cloneTree = (tree) => JSON.parse(JSON.stringify(tree));

const configUpdate = (projectTree, { __typename, ...newConfig }, _args) => {
  const systemPath = `[0].nodes${newConfig.systemPath}`;
  set(projectTree, systemPath, {
    ...get(projectTree, systemPath),
    ...newConfig,
  });

  return projectTree;
};
// MOVE
const configMove = (projectTree, _movedConfig, _args) => projectTree;

const configDelete = (projectTree, _deletedConfig, _args) => projectTree;

const configCopy = (projectTree, _copiedConfig, _args) => projectTree;

export const refreshCacheProjectTree = (action, result, args, cache, info) => {
  let callback = null;
  switch (action) {
    case "createNode":
      callback = nodeCreate;
      break;
    case "updateNode": // updates and/or moves node
      callback = nodeUpdate;
      break;
    case "deleteNode":
      callback = nodeDelete;
      break;
    case "copyNode":
      callback = nodeCopy;
      break;
    case "updateConfig":
      callback = configUpdate;
      break;
    case "deleteConfig":
      callback = configDelete;
      break;
    case "copyConfig":
      callback = configCopy;
      break;
    case "moveConfig":
      callback = configMove;
      break;
    case "addConfig":
      callback = addConfig;
      break;
    default:
  }
  if (!callback) {
    return;
  }

  cache.updateQuery(
    {
      query: ProjectDataQry,
      variables: {
        id:
          args.projectId ||
          new URLSearchParams(document.location.search)?.get("projectId"),
      },
    },
    (data) => ({
      ...data,
      project: data?.project && {
        ...data.project,
        updated: true,
        projectTree: callback(
          cloneTree(data.project.projectTree || []),
          result,
          args,
          info,
        ),
      },
    }),
  );
};
