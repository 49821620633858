import React, { useEffect, useRef } from "react";
import { TransformComponent, TransformWrapper } from "react-zoom-pan-pinch";
import { svgTopology } from "components/_Modals/CreatePDFModal/PreviewTopology/svgTopology";
import { PreviewHeader } from "./PreviewHeader";

export const PreviewTopology = ({
  wizardTitle,
  structuredTopology,
  selectedZone = null,
  innerRef,
}) => {
  const canvasRef = useRef(null);

  useEffect(() => {
    document.getElementById("topologyId").innerHTML = "";
    svgTopology(canvasRef.current, structuredTopology, selectedZone);
  }, [canvasRef, structuredTopology, selectedZone]);

  return (
    <TransformWrapper minScale={0.1} maxScale={3} wheel={{ step: 0.04 }}>
      <TransformComponent wrapperClass="mh-100 mw-100 w-100 p-3 overflow-hidden position-absolute">
        <PreviewHeader wizardTitle={wizardTitle} />
        <div id="topologyWrapperId" ref={innerRef}>
          <svg
            ref={canvasRef}
            id={"topologyId"}
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
          ></svg>
        </div>
      </TransformComponent>
    </TransformWrapper>
  );
};
