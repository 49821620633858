import React, { useCallback } from "react";
import { ModalBody, ModalHeader } from "reactstrap";
import NiceModal from "@ebay/nice-modal-react";
import { ModalWrapper } from "components/ModalWrapper";
import Product3DModel from "components/_ProductConfigurator/SubComponents/Product3DModel";
import { translate } from "utils";

export const Product3dViewModal = NiceModal.create(({ requestParams }) => {
  const modal = NiceModal.useModal();
  const [waitingFor3dModel, setWaitingFor3dModel] = React.useState(true);

  const handleToggleModal = useCallback(() => {
    setWaitingFor3dModel(true);
    modal.resolve();
    modal.remove();
  }, [modal]);

  return (
    <ModalWrapper
      isOpen={modal.visible}
      centered={true}
      size="xl"
      toggle={handleToggleModal}
      isLoading={waitingFor3dModel && waitingFor3dModel !== "failed"}
    >
      <ModalHeader
        toggle={handleToggleModal}
        tag="div"
        className="d-flex align-items-start"
      >
        <h3>{translate("PRODUCT_CONFIGURATOR_3D")}</h3>
      </ModalHeader>
      <ModalBody>
        {waitingFor3dModel === "failed" ? (
          <h5>{translate("PRODUCT_CONFIGURATOR_3D_NOT_AVAILABLE")}</h5>
        ) : (
          <Product3DModel
            requestParams={requestParams}
            setWaitingFor3dModel={setWaitingFor3dModel}
          />
        )}
      </ModalBody>
    </ModalWrapper>
  );
});
