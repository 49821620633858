import { gql } from "@urql/core";

// FRAGMENTS

const ProjectListFrag = gql`
  fragment projectListAttrs on projectList {
    limit
    page
    pages
    projectsCount
    statistics {
      statuses
      shared
      notShared
    }
    overallStatistics {
      statuses
      shared
      notShared
    }
    filters {
      searchQuery
      statuses
      sharedStatusSwitcher
    }
    projects {
      title
      id
      status
      constructionTitle
      createdAt
      updatedAt
      description
      projectNumber
      sharedWith {
        id
      }
      lastEditBy {
        id
        fullName
        email
        lastName
        firstName
      }
    }
  }
`;

const ProjectStatusesFrag = gql`
  fragment projectStatusesAttrs on projectStatusesType {
    status
    color
  }
`;

// QUERIES

const ProjectTreeQry = gql`
  query ($id: String!) {
    project(id: $id) {
      id
      title
      projectTree
      units
    }
  }
`;

const ProjectStatusesQry = gql`
  query {
    currentUser {
      id
      projectStatuses {
        ...projectStatusesAttrs
      }
    }
  }
  ${ProjectStatusesFrag}
`;

const ProjectListQry = gql`
  query ($limit: Int!, $page: Int!) {
    currentUser {
      id
      projectList(limit: $limit, page: $page) {
        ...projectListAttrs
      }
    }
  }
  ${ProjectListFrag}
`;

const ProjectListAndStatusesQry = gql`
  query (
    $limit: Int!
    $page: Int!
    $order: String
    $filters: ProjectListFilters
  ) {
    currentUser {
      id
      projectStatuses {
        ...projectStatusesAttrs
      }
      projectList(
        limit: $limit
        page: $page
        order: $order
        filters: $filters
      ) {
        ...projectListAttrs
      }
    }
  }
  ${ProjectListFrag}
  ${ProjectStatusesFrag}
`;

const ProjectDataQry = gql`
  query ($id: String!) {
    project(id: $id) {
      title
      id
      constructionTitle
      createdAt
      updatedAt
      description
      projectNumber
      version
      status
      units
      country
      projectTree
      owner {
        id
        externalId
        fullName
        email
        lastName
        firstName
      }
      sharedWith {
        id
        fullName
        email
        lastName
        firstName
      }
      lastEditBy {
        id
        fullName
        email
        lastName
        firstName
      }
    }
  }
`;

const ProjectListTitlesAndIds = gql`
  query ($limit: Int!, $page: Int!, $filters: ProjectListFilters) {
    currentUser {
      id
      projectList(limit: $limit, page: $page, filters: $filters) {
        limit
        page
        pages
        projectsCount
        projects {
          title
          id
        }
      }
    }
  }
`;

const ProjectCommissioningCalcQry = gql`
  query (
    $projectId: String
    $projectNodeId: String
    $configurationOption: String!
  ) {
    commissioningCalculation(
      projectId: $projectId
      projectNodeId: $projectNodeId
      configurationOption: $configurationOption
    ) {
      data
    }
  }
`;

const CountryListQry = gql`
  query ($language: String) {
    getCountryList(language: $language) {
      items {
        isoTwoLetterCode
        englishName
        displayName
      }
    }
  }
`;

export {
  ProjectTreeQry,
  ProjectStatusesQry,
  ProjectListQry,
  ProjectListAndStatusesQry,
  ProjectDataQry,
  ProjectListTitlesAndIds,
  ProjectCommissioningCalcQry,
  CountryListQry,
};
