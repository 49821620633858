import { iconsSVG } from "components/_Modals/CreatePDFModal/PreviewTopology/IconsSVG";

const white = "#ffffff";
const gray100 = "#dee0e5";
const gray200 = "#abb0b7";
const gray300 = "#8c8e91";
const gray400 = "#4f5155";

export const nodeHeight = 52;
export const nodeWidth = 276 + nodeHeight;
export const nodeGap = 12;
export const marginBottom = 100;

const nodeKinds = {
  system_controller: {
    title: {
      width: nodeWidth,
      height: nodeHeight,
      stroke: gray400,
      fill: "transparent",
    },
    icon: {
      width: nodeHeight,
      height: nodeHeight,
      fill: gray400,
      iconColor: gray100,
    },
  },
  switch: {
    title: {
      width: nodeHeight,
      height: 20,
      stroke: gray400,
      fill: gray400,
    },
    icon: {
      width: nodeHeight,
      height: nodeHeight,
      stroke: gray400,
      fill: "transparent",
      iconColor: gray400,
    },
  },
  fam: {
    title: {
      width: nodeWidth,
      height: nodeHeight,
      stroke: gray300,
      strokeWidth: "2",
      fill: "transparent",
    },
    icon: {
      width: nodeHeight,
      height: nodeHeight,
      fill: gray300,
      iconColor: gray100,
    },
  },
  splitter: {
    title: {
      width: nodeWidth,
      height: 52,
      stroke: gray200,
      strokeWidth: "2",
      fill: "transparent",
    },
    icon: {
      width: nodeHeight,
      height: nodeHeight,
      fill: gray200,
      iconColor: gray400,
    },
  },

  connectionModule: {
    title: {
      width: nodeWidth,
      height: 52,
      stroke: gray300,
      strokeWidth: "2",
      fill: "transparent",
    },
    icon: {
      width: nodeHeight,
      height: nodeHeight,
      fill: gray300,
      iconColor: gray100,
    },
  },
  component: {
    title: {
      width: nodeWidth,
      height: nodeHeight,
      stroke: gray100,
      strokeWidth: "2",
      fill: "transparent",
    },
    icon: {
      width: nodeHeight,
      height: nodeHeight,
      fill: gray100,
      iconColor: gray400,
    },
  },
};

export const svgMethods = {
  drawLine(ref, startX, startY, endX, endY, { stroke = gray400 }) {
    const d = `M${startX} ${startY}, ${endX} ${endY}`;
    const rect = document.createElementNS("http://www.w3.org/2000/svg", "path");
    rect.setAttribute("d", d);
    rect.setAttribute("stroke", stroke || gray400);
    ref.appendChild(rect);
    // <path d="M20,230 Q40,205 50,230 T90,230" fill="none" stroke="blue" stroke-width="5" />;
  },
  drawRect(
    ref,
    startX,
    startY,
    { width, height, stroke, strokeWidth = 2, fill },
  ) {
    const rect = document.createElementNS("http://www.w3.org/2000/svg", "rect");
    rect.setAttribute("x", startX);
    rect.setAttribute("y", startY);
    rect.setAttribute("width", width);
    rect.setAttribute("height", height);
    rect.setAttribute("stroke", stroke);
    rect.setAttribute("stroke-width", strokeWidth);
    rect.setAttribute("fill", fill);
    ref.appendChild(rect);
  },
  drawIcon(ref, startX, startY, { size = "32", color = gray200, name }) {
    const tagSVG = document.createElementNS(
      "http://www.w3.org/2000/svg",
      "svg",
    );
    tagSVG.setAttribute("x", startX);
    tagSVG.setAttribute("y", startY);
    tagSVG.setAttribute("width", size);
    tagSVG.setAttribute("height", size);
    tagSVG.setAttribute("fill", color);
    tagSVG.innerHTML = iconsSVG(name, color);
    ref.appendChild(tagSVG);
  },
  drawText(
    ref,
    startX,
    startY,
    {
      text,
      fs = 14,
      fw = "regular",
      color = gray400,
      dominantBaseline,
      textAnchor,
      alignmentBaseline,
      transform,
    },
  ) {
    const tagText = document.createElementNS(
      "http://www.w3.org/2000/svg",
      "text",
    );
    tagText.setAttribute("x", startX);
    tagText.setAttribute("y", startY);
    tagText.setAttribute("font-size", fs);
    tagText.setAttribute("font-weight", fw);
    tagText.setAttribute("fill", color);
    dominantBaseline &&
      tagText.setAttribute("dominant-baseline", dominantBaseline);
    textAnchor && tagText.setAttribute("text-anchor", textAnchor);
    alignmentBaseline &&
      tagText.setAttribute("alignment-baseline", alignmentBaseline);
    transform && tagText.setAttribute("transform", transform);
    tagText.insertAdjacentText("afterbegin", text);
    ref.appendChild(tagText);
  },

  drawNode(
    ref,
    startX,
    startY,
    { kind = "component", title, subTitle, iconName },
  ) {
    const paramsByKind = nodeKinds[kind] || nodeKinds.component;
    if (kind === "switch") {
      this.drawRect(ref, startX, startY, paramsByKind.icon);
      this.drawIcon(ref, startX + 10, startY + 10, {
        size: 32,
        color: paramsByKind.icon.iconColor,
        name: iconName,
      });
      this.drawRect(ref, startX, startY + 52, paramsByKind.title);
      this.drawText(
        ref,
        startX + paramsByKind.title.width / 2,
        startY + nodeHeight + paramsByKind.title.height / 2,
        {
          text: title,
          fs: 14,
          fw: "bold",
          color: white,
          dominantBaseline: "middle",
          textAnchor: "middle",
        },
      );
    } else {
      this.drawRect(ref, startX, startY, paramsByKind.icon);
      this.drawIcon(ref, startX + 10, startY + 10, {
        size: 32,
        color: paramsByKind.icon.iconColor,
        name: iconName,
      });
      this.drawRect(ref, startX, startY, paramsByKind.title);
      this.drawText(ref, startX + nodeHeight + 12, startY + 22, {
        text: title,
        fs: 16,
        fw: "bold",
        color: gray400,
      });
      this.drawText(ref, startX + nodeHeight + 12, startY + 38, {
        text: subTitle,
        fs: 14,
        fw: "regular",
        color: gray400,
      });
    }
  },
};
