import React, { useCallback, useRef, useState } from "react";
import * as Scrivito from "scrivito";
import cx from "classnames";
import { getHomepage, translate } from "utils";
import { useClickOutside, useLoginPage } from "hooks";
import { useAppBaseContext, useUserContext } from "providers";
import { Icon } from "../Icon/Icon";
import { LogoutButton } from "./LogoutButton";

import s from "./UserInfo.module.scss";

export const LoginButton = Scrivito.connect(() => {
  const [userMenuOpen, setUserMenuOpen] = useState(false);
  const menuRef = useRef();

  const { isSameProj } = useAppBaseContext();
  const { userFullName, userEmail, isUserLoggedIn } = useUserContext();
  const userName = userFullName || userEmail?.split("@")[0];

  const homepage = getHomepage();
  const welcomePage = homepage.get("welcomePage");
  const loginPage = useLoginPage();

  const onToggleUserMenu = useCallback(() => {
    setUserMenuOpen((val) => !val);
  }, []);

  useClickOutside(menuRef, () => setUserMenuOpen(false));

  return (
    <div className="position-relative d-flex align-items-center">
      <Icon
        prefix="trox"
        iconName="user-light"
        className="icon-32 me-2 text-blue-800 cursor-pointer"
        onClick={() =>
          isUserLoggedIn && Scrivito.navigateTo(welcomePage ?? homepage)
        }
        data-testid="UserInfo_user-profile-icon-btn"
      />
      <div className="login-button-info">
        <div className="login-button-myTROX fs-md fw-bold lh-base text-blue-800">
          {translate("TEXT_MY_TROX")}
        </div>
        <div className="login-button-username lh-sm">
          {isSameProj ? (
            <button
              className="m-0 p-0 bg-white border-0 fs-md fw-bold text-blue-300"
              onClick={onToggleUserMenu}
              data-testid="UserInfo_sameProject-userMenu-btn"
            >
              {userName || translate("TEXT_NOT_LOGGED_IN")}
            </button>
          ) : (
            <Scrivito.LinkTag
              data-testid="UserInfo_login-link"
              to={loginPage}
              params={{ view: "login" }}
            >
              {userFullName || translate("TEXT_NOT_LOGGED_IN")}
            </Scrivito.LinkTag>
          )}
        </div>
      </div>
      {userMenuOpen && (
        <div
          ref={menuRef}
          className={cx(
            "position-absolute d-flex flex-column mt-2 p-2_5 bg-white",
            s.menu,
          )}
        >
          <h3 className="m-0 p-0">
            {userName || translate("TEXT_NOT_LOGGED_IN")}
          </h3>
          <h4 className="m-0 p-0 pb-2_5 border-bottom fs-lg lh-1 text-break">
            {userEmail || translate("TEXT_NOT_LOGGED_IN")}
          </h4>
          <a
            href="https://www.trox.de/en/mytrox/account"
            className="p-2_5 border-bottom text-gray-700"
          >
            {translate("ACCOUNT_SETTINGS")}
          </a>
          <a
            href="https://www.trox.de/en/mytrox/account/new_password"
            className="mb-2_5 p-2_5 border-bottom text-gray-700"
          >
            {translate("CHANGE_PASSWORD")}
          </a>
          <LogoutButton isGray={true} onToggleShow={onToggleUserMenu} />
        </div>
      )}
    </div>
  );
});
