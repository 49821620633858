import React from "react";
import { translate } from "utils";

export const CheckboxAccordionContent = ({ title, itemsData, hasContent }) => {
  const getFileNameOrTitle = (reportTitle, displayedItems) => {
    for (const item of displayedItems) {
      if (item.content) {
        for (const contentItem of item.content) {
          if (contentItem.title === reportTitle) {
            if (contentItem.fromScrivito) {
              return contentItem.title;
            }
            return contentItem.fileName;
          }
        }
      }
    }

    return translate(reportTitle);
  };

  const displayedTitle = getFileNameOrTitle(title, itemsData);

  return hasContent ? (
    <div className="ms-2 font-md text-gray-700">{displayedTitle}</div>
  ) : (
    <label htmlFor={title} style={{ fontSize: "14px" }}>
      {displayedTitle}
    </label>
  );
};
