const replaceString = ({ value, replaceKeysMap }) => {
  let replacedValue = value;

  replaceKeysMap.forEach((replacement, key) => {
    const partialMatchRegex = new RegExp(key, "g");
    replacedValue = replacedValue.replace(partialMatchRegex, replacement);
  });

  return { replacedValue };
};

export const findTempNodes = ({ nodes: inputNodes, replaceKeysMap }) => {
  const tempNodes = [];

  function processNode(node) {
    if (typeof node === "object" && node !== null) {
      const updatedNode = { ...node };

      [("id", "parentId")].forEach((key) => {
        if (typeof updatedNode[key] === "string") {
          const { replacedValue } = replaceString({
            value: updatedNode[key],
            replaceKeysMap,
          });
          updatedNode[key] = replacedValue;
        }
      });

      if (
        (updatedNode.id && updatedNode.id.startsWith("temp__")) ||
        (updatedNode.parentId && updatedNode.parentId.startsWith("temp__"))
      ) {
        const { nodes: _nodes, ...rest } = updatedNode;
        tempNodes.push(rest);
      }

      if (Array.isArray(updatedNode.nodes)) {
        updatedNode.nodes.forEach(processNode);
      }
    }
  }

  inputNodes.forEach(processNode);

  return tempNodes;
};

export const updateCreateOperationWithKeysReplacement = ({
  operation,
  replaceKeysMap,
}) => {
  if (
    replaceKeysMap.size === 0 &&
    operation.variables?.input?.parentId?.startsWith("temp__")
  ) {
    return { shouldBeInQueue: true };
  }

  const { replacedValue } = replaceString({
    value: operation.variables?.input?.parentId,
    replaceKeysMap,
  });

  operation.variables.input.parentId = replacedValue;
  return { shouldBeInQueue: replacedValue?.startsWith("temp__") };
};

export const updateCopyOperationWithKeysReplacement = ({
  operation,
  replaceKeysMap,
}) => {
  let hasTempInside = false;
  const { destinationId, id } = operation.variables;
  const { childNodes } = operation.context;
  if (id.startsWith("temp__")) {
    const { replacedValue } = replaceString({
      value: id,
      replaceKeysMap,
    });
    operation.variables.id = replacedValue;
    if (replacedValue?.startsWith("temp__")) {
      hasTempInside = true;
    }
  }

  if (destinationId?.startsWith("temp__")) {
    const { replacedValue } = replaceString({
      value: destinationId,
      replaceKeysMap,
    });
    operation.variables.destinationId = replacedValue;
    if (replacedValue?.startsWith("temp__")) {
      hasTempInside = true;
    }
  }

  if (childNodes?.length !== 0) {
    const tempNodes = findTempNodes({ nodes: childNodes, replaceKeysMap });
    operation.context.childNodes = tempNodes;
    if (tempNodes.length !== 0) {
      hasTempInside = true;
    }
  }

  return { shouldBeInQueue: hasTempInside };
};

export const updateOrLaunchOperations = ({
  queue,
  tempMapToUpdateQueue,
  client,
}) => {
  for (const operation of queue) {
    let shouldBeExecuted = false;
    switch (operation.context.projectPauseExchangeType) {
      case "nodeCreate": {
        const { shouldBeInQueue } = updateCreateOperationWithKeysReplacement({
          operation,
          replaceKeysMap: tempMapToUpdateQueue,
        });

        shouldBeExecuted = !shouldBeInQueue;
        break;
      }
      case "nodeCopy": {
        const { shouldBeInQueue } = updateCopyOperationWithKeysReplacement({
          operation,
          replaceKeysMap: tempMapToUpdateQueue,
        });
        shouldBeExecuted = !shouldBeInQueue;
        break;
      }
      default:
        break;
    }

    if (shouldBeExecuted) {
      const updatedOperation = {
        ...operation,
        context: {
          ...operation.context,
          requestPolicy: "network-only",
          fromQueue: true,
        },
      };
      client.reexecuteOperation(updatedOperation);
      queue.delete(operation);
    }
  }
};
