import React, { useCallback, useMemo, useState } from "react";
import * as Scrivito from "scrivito";
import NiceModal from "@ebay/nice-modal-react";
import { ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import { getHomepage, translate } from "utils";
import { useQuery } from "urql";
import { ProjectCreateMut } from "api/mutations";
import { ProjectListTitlesAndIds } from "../../api/queries";
import { ModalWrapper } from "../ModalWrapper";
import { Button } from "../Button";
import { Select } from "../Select/Select";
import { ProjectDetailsModal } from "./ProjectDetailsModal/ProjectDetailsModal";

export const AddWizardToProject = NiceModal.create(
  Scrivito.connect(({ wizardItem = {}, setActiveButton = () => {} }) => {
    const modal = NiceModal.useModal();

    const [selectedProject, setSelectedProject] = useState(null);

    const handleCloseModal = useCallback(() => {
      modal.resolve();
      modal.remove();
    }, [modal]);

    const [{ data, fetching }] = useQuery({
      query: ProjectListTitlesAndIds,
      variables: {
        limit: 1000,
        page: 1,
      },
      requestPolicy: "network-only",
    });

    const { projects } = data?.currentUser?.projectList || [];

    const options = useMemo(
      () => projects?.map((item) => ({ value: item.id, label: item.title })),
      [projects],
    );

    const configWizardObjRefs = getHomepage().get("configWizardObjRefs");
    const wizardObjRef = configWizardObjRefs.find(
      (item) => item.objClass() === "Wizard",
    );

    const subType = wizardItem.get("subType");

    const saveHandler = useCallback(
      (id) => {
        Scrivito.navigateTo(() => wizardObjRef || Scrivito.currentPage(), {
          params: {
            subType,
            projectId: id,
            step: "0",
          },
        });
        handleCloseModal();
        setActiveButton(null);
      },
      [handleCloseModal, subType, wizardObjRef, setActiveButton],
    );

    const toggleProjectDetailsModal = useCallback(() => {
      NiceModal.show(ProjectDetailsModal, { mutation: ProjectCreateMut }).then(
        ({ id }) => {
          saveHandler(id);
          handleCloseModal();
        },
      );
    }, [saveHandler, handleCloseModal]);
    return (
      <ModalWrapper
        isOpen={modal.visible}
        centered={true}
        size="md"
        toggle={modal.hide}
        isLoading={options === undefined || fetching}
      >
        <ModalHeader tag="div" className="d-flex align-items-start pb-0">
          <h3 className="text-uppercase mb-1">{translate("ADD_TO_PROJECT")}</h3>

          <h4 className="mb-2_5"> {translate("PLEASE_CHOOSE_PROJECT")}</h4>
          <div>{translate("PLEASE_CHOOSE_PROJECT_TEXT")}</div>
        </ModalHeader>
        <ModalBody className="m-0 pt-2_5 d-flex row pb-0">
          <h5 className="mb-2 p-0"> {translate("EXISTING_PROJECTS")}</h5>

          <Select
            name={`config-`}
            data-recording-disable={true}
            options={options}
            onChange={(e) => setSelectedProject(e.value)}
            className="p-0"
            testId="add-wizard-to-project-select"
          />
          <Button
            data-testid="AddWizardToProject_create-new-project-btn"
            color="secondary"
            outline
            iconPrefix="trox"
            onClick={toggleProjectDetailsModal}
            classNames="btn-sm w-auto m-3 ms-0"
          >
            {translate("CREATE_A_NEW_PROJECT")}
          </Button>
        </ModalBody>
        <ModalFooter className="pt-0">
          <div className="m-0 d-flex">
            <Button
              data-testid="AddWizardToProject_create-save-btn"
              color="primary"
              iconPrefix="trox"
              onClick={() => saveHandler(selectedProject)}
              classNames="m-1"
              disabled={!selectedProject}
            >
              {translate("SAVE")}
            </Button>
          </div>
        </ModalFooter>
      </ModalWrapper>
    );
  }),
);
