import React from "react";
import * as Scrivito from "scrivito";
import ReactSelect, { components as ReactSelectComponents } from "react-select";
import cx from "classnames";
import { translate } from "utils";
import { TranslateWrapper } from "components/TranslateWrapper";
import styles from "./Select.module.scss";

export const Select = Scrivito.connect(({ testId = null, ...props }) => {
  const dot = (color = null) => ({
    alignItems: "center",
    display: "flex",

    ":before": {
      backgroundColor: color,
      borderRadius: 10,
      border: color ? "1px solid white" : "none",
      content: '" "',
      display: "block",
      marginRight: color ? 8 : 0,
      height: color ? "0.75rem" : 0,
      width: color ? "0.75rem" : 0,
    },
  });

  const colourStyles = {
    option: (optionStyles, { data }) => ({
      ...optionStyles,
      ...dot(data.color),
      cursor: "pointer",
    }),
    singleValue: (optionStyles, { data }) => ({
      ...optionStyles,
      ...dot(data.color),
    }),
    control: (controlStyles) => ({
      ...controlStyles,
      cursor: "pointer",
    }),
  };

  const Control = ({ children, ...controlProps }) => (
    <ReactSelectComponents.Control
      {...controlProps}
      className={cx(styles.wrapper, "rounded-1", {
        "border-info": controlProps.isFocused,
        "border-danger": controlProps.selectProps.isInvalid,
      })}
    >
      {children}
    </ReactSelectComponents.Control>
  );

  const ValueContainer = ({ children, ...valueContainerProps }) => (
    <ReactSelectComponents.ValueContainer
      {...valueContainerProps}
      className="py-0"
    >
      {children}
    </ReactSelectComponents.ValueContainer>
  );

  const Input = (inputProps) => <ReactSelectComponents.Input {...inputProps} />;

  const DropdownIndicator = (dropdownIndProps) => (
    <ReactSelectComponents.DropdownIndicator
      {...dropdownIndProps}
      className={cx("py-0", {
        "rotate-180": dropdownIndProps.selectProps.menuIsOpen,
      })}
    />
  );

  const Option = ({ isFocused, isSelected, children, ...optionProps }) => (
    <ReactSelectComponents.Option
      {...optionProps}
      className={cx({
        "bg-blue-300 text-white": isFocused,
        "bg-blue-400 text-white": isSelected,
      })}
    >
      <div data-testid={optionProps.value}>{children}</div>
    </ReactSelectComponents.Option>
  );

  const MenuList = ({ children, ...menuListProps }) => (
    <ReactSelectComponents.MenuList
      {...menuListProps}
      className={cx("p-0", styles.menuList)}
    >
      {children}
    </ReactSelectComponents.MenuList>
  );

  const placeholderText = translate("SELECT");

  const Placeholder = (placeholderProps) => (
    <ReactSelectComponents.Placeholder {...placeholderProps}>
      {placeholderText}
      {typeof placeholderText === "object" && (
        <TranslateWrapper
          tooltipText={placeholderText.props.tooltipText}
          tooltipId="select-placeholder"
        />
      )}
    </ReactSelectComponents.Placeholder>
  );

  const components = {
    Control,
    ValueContainer,
    DropdownIndicator,
    Option,
    MenuList,
    Input,
    Placeholder,
  };

  return (
    <div data-testid={testId} id="select-placeholder">
      <ReactSelect
        components={components}
        styles={colourStyles}
        classNamePrefix={testId}
        isDisabled={props.disabled}
        {...props}
      />
    </div>
  );
});
