export const excludedReportsTitles = ["PRODUCT_INFORMATION", "BIM Data"];

export const items = [
  {
    title: "PRODUCT_INFORMATION",
    content: [
      {
        id: 1,
        title: "PRODUCT_INFORMATION_REPORT",
        extension: "pdf",
        fileName:
          "IM_2018_06_A00000054447 | Installation manual | 1,2 MB | pdf",
        checked: false,
      },
    ],
    extension: "pdf",
    checked: false,
  },
  // {
  //   title: "SPECIFICATION_TEXT",
  //   content: [
  //     {
  //       id: 1,
  //       title: "Installation manual Air terminal devices",
  //       fileName:
  //         "IM_2018_06_A00000054447 | Installation manual | 1,2 MB | pdf",
  //     },
  //   ],
  //   checked: false,
  // },
  {
    title: "BIM_DATA",
    content: [
      {
        id: 1,
        title: "BIM Data",
        extension: "xml",
        fileName:
          "IM_2018_06_A00000054447 | Would be nice to have some strings here | 1,2 MB | xml",
      },
    ],
    checked: false,
    extension: "xml",
  },
  {
    title: "PRODUCT_CONFIGURATOR_MANUALS",
    content: [],
    checked: false,
  },
  {
    title: "PRODUCT_CONFIGURATOR_CERTIFICATE",
    content: [],
    checked: false,
  },
];

export const systemExportItems = [
  {
    title: "PROJECT_REPORT",
    id: 1,
    content: [
      {
        id: "Project Report",
        // title: "Installation manual Air terminal devices",
        // fileName:
        //   "IM_2018_06_A00000054447 | Installation manual | 1,2 MB | pdf",
      },
    ],
    checked: true,
    skipContentRender: true,
  },
  // {
  //   title: "BIM Data",
  //   content: [
  //     {
  //       id: 1,
  //       title: "Installation manual Air terminal devices",
  //       fileName:
  //         "IM_2018_06_A00000054447 | Installation manual | 1,2 MB | pdf",
  //     },
  //   ],
  //   checked: true,
  // },
  {
    title: "PRODUCT_LIST",
    id: 2,
    content: [
      {
        id: "BOM",
        // title: "Installation manual Air terminal devices",
        // fileName:
        //   "IM_2018_06_A00000054447 | Installation manual | 1,2 MB | pdf",
      },
    ],
    checked: true,
    skipContentRender: true,
  },
  // {
  //   title: "System Description",
  //   content: [
  //     {
  //       id: 1,
  //       title: "Installation manual Air terminal devices",
  //       fileName:
  //         "IM_2018_06_A00000054447 | Installation manual | 1,2 MB | pdf",
  //     },
  //   ],
  //   checked: true,
  // },
];

// export const productDataItems = [
//   {
//     title: "Airnamic",
//     sublist: false,
//     content: [
//       {
//         id: 1,
//         title: "Installation manual Air terminal devices",
//         fileName:
//           "IM_2018_06_A00000054447 | Installation manual | 1,2 MB | pdf",
//       },
//     ],
//     checked: true,
//   },
//   {
//     title: "TVE",
//     sublist: true,
//     content: items,
//     checked: true,
//   },
// ];
