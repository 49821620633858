export const PROJECT_PAGES = [
  "ProjectList",
  "ProjectItem",
  "ProjectConfigurator",
];
export const PROJECT_PAGES_SAME = [
  "ProjectListSame",
  "ProjectItemSame",
  "ProjectConfigurator",
];

export const PRODUCT_PAGES = ["ProductList", "ProductConfigurator"];

export const WIZARD_PAGES = ["WizardList", "Wizard"];

export const LIBRARY_PAGES = [
  "LibraryCustom",
  "LibraryList",
  "LibraryTemplates",
];

export const HELP_PAGES = ["ConfiguratorHelpPage"];

export const ADMIN_PAGES = ["UserList", "GroupList", "DepartmentList"];

export const PAGES_WITH_LEFT_SIDEBAR = [
  ...PROJECT_PAGES,
  ...PROJECT_PAGES_SAME,
  ...PRODUCT_PAGES,
  ...LIBRARY_PAGES,
  ...WIZARD_PAGES,
  ...ADMIN_PAGES,
];

export const LEFT_SIDEBAR_BUTTONS_ARRAY_CONF = [
  {
    title: "LEFT_SIDEBAR_PROJECT",
    iconName: "project-tree-light",
    prefix: "trox",
    refProp: "configLeftSidebarProjectRefs",
    pageTypes: PROJECT_PAGES,
  },
  {
    title: "LEFT_SIDEBAR_PRODUCTS",
    iconName: "cube-light",
    prefix: "trox",
    refProp: "configLeftSidebarProductsRefs",
    pageTypes: PRODUCT_PAGES,
  },
  {
    title: "LEFT_SIDEBAR_WIZARDS",
    iconName: "magic-wand-light",
    prefix: "trox",
    refProp: "configWizardObjRefs",
    pageTypes: WIZARD_PAGES,
  },
  {
    title: "LEFT_SIDEBAR_LIBRARY",
    iconName: "book-open-light",
    prefix: "trox",
    refProp: "configLeftSidebarLibraryRefs",
    pageTypes: LIBRARY_PAGES,
  },
];

export const LEFT_SIDEBAR_BUTTONS_ARRAY_SAME = [
  {
    title: "LEFT_SIDEBAR_PROJECT",
    iconName: "project-tree-light",
    prefix: "trox",
    refProp: "configLeftSidebarProjectRefs",
    pageTypes: PROJECT_PAGES_SAME,
  },
  {
    title: "LEFT_SIDEBAR_PRODUCTS",
    iconName: "cube-light",
    prefix: "trox",
    refProp: "configLeftSidebarProductsRefs",
    pageTypes: PRODUCT_PAGES,
  },
  // {
  //   title: "QUOTES",
  //   iconName: "icon-quotes",
  //   prefix: "trox",
  //   refProp: "configLeftSidebarProjectRefs",
  //   pageTypes: PROJECT_PAGES,
  // },
  // {
  //   title: "REVIEWS",
  //   iconName: "magic-wand-light",
  //   prefix: "trox",
  //   refProp: "configLeftSidebarProjectRefs",
  //   pageTypes: PROJECT_PAGES,
  // },
  // {
  //   title: "CUSTOMERS",
  //   iconName: "user-group-light",
  //   prefix: "trox",
  //   refProp: "configLeftSidebarProjectRefs",
  //   pageTypes: PROJECT_PAGES,
  // },
  // {
  //   title: "LEFT_SIDEBAR_ADMIN",
  //   iconName: "icon-admin",
  //   prefix: "trox",
  //   refProp: "configLeftSidebarAdminRefs",
  //   pageTypes: ADMIN_PAGES,
  // },
];

export const HELP_BTN = {
  iconName: "question-circle-light",
  prefix: "trox",
  refProp: "configLeftSidebarHelpRefs",
  pageTypes: HELP_PAGES,
  title: "HELP_PAGE",
};

export const CHANGE_LOG_BTN = {
  iconName: "question-circle-light",
  prefix: "trox",
  refProp: "configLeftSidebarChangeLogRefs",
  pageTypes: ["ChangeLogPage"],
  title: "CHANGE_LOG",
};

const [projBtn, prodBtn, wizBtn, libBtn] = LEFT_SIDEBAR_BUTTONS_ARRAY_CONF;

const [projBtnSame, prodBtnSame, admBtnSame] = LEFT_SIDEBAR_BUTTONS_ARRAY_SAME;

export const LEFT_SIDEBAR_BUTTONS = {
  projBtn,
  prodBtn,
  wizBtn,
  libBtn,
  admBtnSame,
  prodBtnSame,
  projBtnSame,
};
