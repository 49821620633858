import { useCallback, useMemo, useState } from "react";
import { useQuery } from "urql";
import { DepartmentListQry } from "api/queries/user";
import { useAppBaseContext } from "../providers/AppBaseProvider";

export const useUserDepartments = () => {
  const { isSameProj } = useAppBaseContext();
  const unparsedCacheDept = localStorage.getItem("department");
  const cacheDept =
    isSameProj && unparsedCacheDept ? safeParseJSON(unparsedCacheDept) : null;
  const [selectedDepartment, setSelectedDepartment] = useState(cacheDept);
  const [{ data: departmentsData, error, fetching, stale }] = useQuery({
    query: DepartmentListQry,
    variables: {
      myDepartments: true,
      page: 1,
      size: 1000,
    },
    requestPolicy: "cache-first",
    pause: !isSameProj,
  });

  const isLoading = fetching || stale;

  const departmentsOptions = useMemo(() => {
    if (departmentsData?.getDepartments?.results && !isLoading) {
      const {
        getDepartments: { results: departments },
      } = departmentsData;
      const options = departments.map((dept) => ({
        value: dept.id,
        label: dept.name,
      }));
      let matchExists = false;
      selectedDepartment?.value &&
        options.forEach((opt) => {
          if (opt.value === selectedDepartment.value) {
            matchExists = true;
          }
        });
      if ((!selectedDepartment || !matchExists) && options.length > 0) {
        setSelectedDepartment(options[0]);
        localStorage.setItem("department", JSON.stringify(options[0]));
      }
      if (options.length === 0) {
        localStorage.removeItem("department");
        setSelectedDepartment();
      }
      return options;
    }
    return null;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading]);

  const handleSelect = useCallback((option) => {
    setSelectedDepartment(option);
    localStorage.setItem("department", JSON.stringify(option));
  }, []);

  return {
    options: departmentsOptions || [],
    selected: selectedDepartment || null,
    setSelected: handleSelect,
    error: error || null,
  };
};

const safeParseJSON = (data) => {
  try {
    if (!data) {
      throw new Error(
        `Cannot JSON.parse value of: "${data}" and type of: ${typeof data}`,
      );
    }
    const parsedJson = JSON.parse(data);
    return parsedJson;
  } catch (error) {
    console.error(error);
    return null;
  }
};
