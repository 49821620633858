import React, { useCallback, useState } from "react";
import { Accordion } from "reactstrap";
import { ProjectDownloadAccordionItem } from "./ProjectDownloadAccordionItem";
import { ProjectDownloadSortDropdown } from "./ProjectDownloadSortDropdown";
import { ProjectDownloadAccordionItemContent } from "./ProjectDownloadAccordionItemContent";
import { ScrivitoDocumentsDownloadItem } from "./ScrivitoDocumentsDownloadItem";

export const ModalCheckboxesBlock = ({
  data,
  blockTitle,
  setData,
  showProducts,
  seriesId,
}) => {
  const [open, setOpen] = useState("");
  const [sublistItemOpen, setSublistItemOpen] = useState("");

  const toggle = useCallback((id) => {
    setOpen((prevValue) => (prevValue === id ? "" : id));
  }, []);

  const toggleSublist = useCallback((id) => {
    setSublistItemOpen((prevValue) => (prevValue === id ? "" : id));
    setOpen("");
  }, []);

  return (
    <div className="flex-xs-column d-flex gap-2">
      <div className="d-flex justify-content-between align-items-center">
        <h3 className="text-uppercase mb-0 font20">{blockTitle}</h3>
        <ProjectDownloadSortDropdown showProducts={showProducts} />
      </div>
      <Accordion open={open} toggle={() => {}}>
        {data &&
          data.map((item) => {
            if (
              item.title === "PRODUCT_CONFIGURATOR_CERTIFICATE" ||
              item.title === "PRODUCT_CONFIGURATOR_MANUALS"
            ) {
              return (
                <ScrivitoDocumentsDownloadItem
                  key={item.title}
                  item={item}
                  seriesId={seriesId}
                  data={data}
                  toggle={toggle}
                  setData={setData}
                  open={open}
                />
              );
            }

            return (
              <ProjectDownloadAccordionItem
                open={open}
                title={item.title}
                key={item.title}
                toggle={toggle}
                checked={item.checked}
                setItemsData={setData}
                seriesId={seriesId}
                hasContent={item.content.length > 0}
                itemsData={data}
              >
                {!item.skipContentRender && (
                  <ProjectDownloadAccordionItemContent
                    item={item}
                    toggleSublist={toggleSublist}
                    sublistItemOpen={sublistItemOpen}
                    seriesId={seriesId}
                    setData={setData}
                    data={data}
                  />
                )}
              </ProjectDownloadAccordionItem>
            );
          })}
      </Accordion>
    </div>
  );
};
