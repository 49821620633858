import React, { useCallback, useEffect } from "react";
import * as Scrivito from "scrivito";
import { Alert, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import NiceModal from "@ebay/nice-modal-react";
import { translate } from "utils";
import { useMutation } from "urql";
import { Spinner } from "../Spinner";
import { Button } from "../Button";
import { Icon } from "../Icon/Icon";
import { Marker } from "../Marker";

export const ConfirmationModal = NiceModal.create(
  Scrivito.connect(
    ({
      iconName = "trash-can-light",
      title,
      alertText,
      submitBtnText,
      mutation,
      params,
      onConfirm,
      displayList,
      onCancel,
      mutationContext,
    }) => {
      const modal = NiceModal.useModal();
      const [{ data, fetching }, execute] = useMutation(mutation);
      useEffect(() => {
        if (data) {
          modal.resolve(data);
          modal.remove();
        }
      }, [data, modal]);

      const handleSubmit = useCallback(() => {
        if (onConfirm) {
          onConfirm();
          modal.resolve();
          modal.remove();
          return;
        }
        if (mutationContext) {
          execute(params, mutationContext);
          modal.resolve();
          modal.remove();
          return;
        }

        execute(params);
      }, [onConfirm, mutationContext, execute, params, modal]);

      const handleCancel = useCallback(() => {
        if (onCancel) {
          onCancel();
          modal.resolve();
          modal.remove();
        } else {
          modal.hide();
        }
      }, [onCancel, modal]);

      return (
        <Modal
          isOpen={modal.visible}
          centered={true}
          size="md"
          toggle={modal.hide}
          className="confirmation-modal"
        >
          <ModalHeader toggle={modal.hide} tag="div" className="pb-0" />
          <ModalBody className="pt-2_5 d-flex row m-0">
            <div className="text-center pb-3">
              <Icon
                iconName={iconName}
                className="icon-48 text-gray-400"
                title={iconName}
                prefix="trox"
              />
              <h5 className="mb-0 pt-2 text-gray-700">{title}</h5>
            </div>
            <Alert
              color="danger"
              className="ml-4 mr-4 text-red overflow-hidden p-0 fs-md d-flex flex-nowrap"
            >
              <Marker color="red" />
              <div className="d-inline-block align-top p-2_5">
                <div className="fw-bold mb-1">{translate("TEXT_WARNING")}</div>
                <div className="m-0">{alertText}</div>
                {displayList && (
                  <ul>
                    {displayList.map((proj) => (
                      <li key={proj.id}>{proj.title}</li>
                    ))}
                  </ul>
                )}
              </div>
            </Alert>
          </ModalBody>
          <ModalFooter className="pt-0">
            <Button className="btn-tertiary me-3" onClick={handleCancel}>
              {translate("CANCEL")}
            </Button>
            <Button
              className="d-flex align-items-center gap-2_5 btn-secondary me-3"
              disabled={fetching}
              onClick={handleSubmit}
            >
              {submitBtnText}
              {fetching && <Spinner size="xxs" />}
            </Button>
          </ModalFooter>
        </Modal>
      );
    },
  ),
);
