import React, { useCallback, useMemo, useRef, useState } from "react";
import cx from "classnames";
import { useMutation } from "urql";
import { Input, UncontrolledTooltip } from "reactstrap";
import NiceModal from "@ebay/nice-modal-react";
import { toast } from "react-toastify";

import { useClickOutside } from "hooks";

import { IconSet } from "components/IconSet";
import { Button } from "components/Button";
import { TranslateWrapper } from "components/TranslateWrapper";
import { FolderWrapper } from "components/_StructureTree/FolderWrapper";
import { SingleProductDownloadModal } from "components/_Modals/ProjectDownloadModal/SingleProductDownloadModal";
import { WizardLink } from "components/ProjectTree/WizardLink";
import { ConfirmationModal } from "components/_Modals/ConfirmationModal";
import { useProductAddingModalContext, useProjectTreeContext } from "providers";

import {
  AddProductToNodeMut,
  NodeCopyMut,
  NodeCreateMut,
  NodeDeleteMut,
  NodeUpdateMut,
} from "api/mutations";
import { ConfigCopyMut } from "api/mutations/configurations";
import { debounce } from "lodash-es";
import { REFRESH_DELAY } from "variables";
import { translate } from "utils";
import { genNewNodeTitle } from "./FolderHelper";
import { Icon } from "../../Icon/Icon";
import styles from "./Folder.module.scss";
import s from "../../_StructureTree/StructureTree.module.scss";

export const Folder = React.memo(
  ({
    dropArea,
    folderWrapperRef,
    handleToggle,
    hasChildren,
    id,
    isDragging = false,
    isDragPreview = false,
    isIconsShown = true,
    isLeftSidebar = false,
    isOpen,
    isRootNode = false,
    isWizard = false,
    isWizardWithLink,
    localPath,
    parentId = null,
    referencePosition,
    title,
    nodes,
    siblingsNodes,
    projectName,
    ulRef,
    configurations,
    ...restProps
  }) => {
    const {
      projectId,
      copiedItem,
      handleStartCopy,
      translations,
      exeGetProjectTree,
      withDnd,
      pasteModeOnly,
      isSummary,
    } = useProjectTreeContext();

    const exeGetProjectTreeDebounced = useMemo(
      () => debounce(exeGetProjectTree, REFRESH_DELAY),
      [exeGetProjectTree],
    );

    const {
      addLayer,
      alertTextCaution,
      getDeleteModalBodyPluralWithTitleParam,
      getDeleteModalBodySingularWithTitleParam,
      submitBtnTextNoChild,
      submitBtnTextWithChild,
      titleNoChild,
      titleWithChild,
    } = translations.folder;

    const inputRef = useRef(null);
    const [inputValue, setInputValue] = useState(title || "");
    const [isEditMode, changeIsEditMode] = useState(false);

    const { addedProducts, setAddedProducts } = useProductAddingModalContext();

    const handleToggleEditMode = useCallback(() => {
      if (isRootNode || isWizard) {
        return;
      }
      changeIsEditMode((val) => !val);
    }, [isRootNode, isWizard]);
    const handleInputChange = useCallback((e) => {
      setInputValue(e.target.value);
    }, []);

    const openDownloadModal = useCallback(() => {
      NiceModal.show(SingleProductDownloadModal, {
        modalSubtitle: title,
        modalType: "Node",
      });
    }, [title]);

    const scrollToNewNode = useCallback(() => {
      const divToScroll = ulRef?.current?.lastChild;
      if (divToScroll) {
        divToScroll.scrollIntoView({ block: "center", behavior: "smooth" });
      }
    }, [ulRef]);

    // ---------- UPDATE NODE ------------------------------------------------------------------

    const [{ fetching: fetchingUpdateNode }, exeUpdateNode] =
      useMutation(NodeUpdateMut);

    const handleUpdate = useCallback(() => {
      exeUpdateNode(
        {
          id,
          input: { title: inputValue },
        },
        {
          projectPauseExchangeType: "nodeUpdate",
        },
      );
      handleToggleEditMode();
    }, [exeUpdateNode, handleToggleEditMode, id, inputValue]);

    // ---------- CREATE NODE ------------------------------------------------------------------

    const [_, exeCreateNode] = useMutation(NodeCreateMut);

    const handleCreate = useCallback(() => {
      const newTitle = genNewNodeTitle(title, nodes, true);
      exeCreateNode(
        {
          projectId,
          input: {
            title: newTitle,
            parentId: id || null,
          },
          referencePosition: nodes ? nodes.length + 1 : 1,
        },
        { projectPauseExchangeType: "nodeCreate" },
      ).then(() => scrollToNewNode());
    }, [title, nodes, exeCreateNode, projectId, id, scrollToNewNode]);

    // ---------- DELETE NODE ------------------------------------------------------------------
    const handleDelete = useCallback(() => {
      NiceModal.show(ConfirmationModal, {
        submitBtnText: hasChildren
          ? submitBtnTextWithChild
          : submitBtnTextNoChild,
        title: hasChildren ? titleWithChild : titleNoChild,
        alertText: `${
          hasChildren
            ? getDeleteModalBodyPluralWithTitleParam([title])
            : getDeleteModalBodySingularWithTitleParam([title])
        } ${alertTextCaution}`,
        mutation: NodeDeleteMut,
        params: { id },
        mutationContext: { projectPauseExchangeType: "nodeDelete" },
      });
    }, [
      hasChildren,
      submitBtnTextWithChild,
      getDeleteModalBodyPluralWithTitleParam,
      getDeleteModalBodySingularWithTitleParam,
      submitBtnTextNoChild,
      titleWithChild,
      titleNoChild,
      title,
      alertTextCaution,
      id,
    ]);
    // ---------- COPY NODE/CONFIG (DUPLICATION & COPY/PASTE)
    // --------------------------------------------
    const [_copyNode, exeCopyNode] = useMutation(NodeCopyMut);

    const [_a, exeAddProductToNode] = useMutation(AddProductToNodeMut);

    const [
      { _configCopyData, fetching: fetchingCopyConfiguration },
      exeCopyConfiguration,
    ] = useMutation(ConfigCopyMut);

    const handleStartCopying = useCallback(() => {
      handleStartCopy({
        id,
        title,
        localPath,
        type: "node",
        isWizard,
        childNodes: nodes,
        configurations,
      });
    }, [
      handleStartCopy,
      id,
      title,
      localPath,
      isWizard,
      nodes,
      configurations,
    ]);

    const handleDuplicateNode = useCallback(() => {
      const newTitle = genNewNodeTitle(title, siblingsNodes);
      exeCopyNode(
        {
          id,
          input: {
            title: newTitle,
          },
          referencePosition: referencePosition + 1,
          destinationId: parentId,
          childNodes: nodes,
          configurations,
        },
        {
          projectPauseExchangeType: "nodeCopy",
          childNodes: nodes,
          configurations,
        },
      );
    }, [
      title,
      siblingsNodes,
      exeCopyNode,
      id,
      referencePosition,
      parentId,
      nodes,
      configurations,
    ]);

    const handlePasteNode = useCallback(() => {
      if (isWizard || isRootNode) {
        return;
      }
      if (pasteModeOnly) {
        exeAddProductToNode(
          { nodeId: id, ...copiedItem },
          {
            projectPauseExchangeType: "addConfiguration",
          },
        ).then(({ data }) => {
          const addedConfiguration = data?.addProjectNodeConfiguration;
          if (setAddedProducts && addedConfiguration) {
            setAddedProducts([...addedProducts, addedConfiguration.id]);
          }
          if (data.addProjectNodeConfiguration) {
            const copiedProductName = copiedItem.productName;
            toast.info(
              translate("PRODUCT_SUCCESSFUL_ADD_TO_PROJECT", [
                copiedProductName,
                projectName || "",
              ]),
              {
                type: "success",
                theme: "colored",
              },
            );
          }
        });
      } else {
        handleStartCopy(null);
        if (copiedItem.type === "node") {
          const newTitle = genNewNodeTitle(copiedItem.title, nodes);
          exeCopyNode(
            {
              id: copiedItem.id,
              input: {
                title: newTitle,
              },
              referencePosition: 1,
              destinationId: id,
              childNodes: copiedItem.childNodes,
              configurations,
            },
            {
              projectPauseExchangeType: "nodeCopy",
              childNodes: copiedItem.childNodes,
              configurations,
            },
          );
        }
        if (copiedItem.type === "configuration") {
          !fetchingCopyConfiguration &&
            exeCopyConfiguration({
              id: copiedItem.id,
              destinationNodeId: id,
            }).then(({ data }) => {
              const newNode = data.copyProjectNodeConfiguration;
              exeUpdateNode({
                id: newNode.nodeId,
              });
            });
          exeGetProjectTreeDebounced();
        }
      }
    }, [
      isWizard,
      isRootNode,
      pasteModeOnly,
      exeAddProductToNode,
      id,
      copiedItem,
      setAddedProducts,
      addedProducts,
      exeUpdateNode,
      exeGetProjectTreeDebounced,
      projectName,
      handleStartCopy,
      nodes,
      exeCopyNode,
      configurations,
      fetchingCopyConfiguration,
      exeCopyConfiguration,
    ]);

    // ----------- OTHERS --------------------------------------------------------------------------
    useClickOutside(
      inputRef,
      () => {
        handleUpdate();
      },
      isEditMode,
    );
    const handleInputKeyDown = useCallback(
      (e) => {
        if (e.key === "Enter") {
          handleUpdate();
        }
      },
      [handleUpdate],
    );

    return (
      <FolderWrapper
        className={cx("text-gray-400 px-1", {
          [styles.wizard]: isWizard,
          "px-2": isWizard,
          border: !isEditMode,
          [styles.editMode]: isEditMode,
        })}
        dropArea={dropArea}
        folderWrapperRef={folderWrapperRef}
        handlePaste={handlePasteNode}
        hasChildren={hasChildren}
        isDragging={isDragging}
        isDragPreview={isDragPreview}
        isDroppable={!isWizard}
        itemToCopy={!isRootNode && copiedItem}
        isSummary={isSummary}
        iconset={
          !pasteModeOnly &&
          isIconsShown &&
          !isRootNode && (
            <IconSet
              id={id}
              isEditable={!isWizard && !isRootNode}
              isDuplicatable={isWizardWithLink || !isWizard}
              isEditMode={isEditMode}
              isDisabled={fetchingUpdateNode}
              handleToggleEditMode={handleToggleEditMode}
              onDelete={handleDelete}
              onDownload={openDownloadModal}
              onDuplicate={handleDuplicateNode}
              onStartCopying={handleStartCopying}
              isWizard={!isWizardWithLink && isWizard}
            />
          )
        }
        translations={translations.folder}
        id={id}
        {...restProps}
      >
        {!isWizard && !isRootNode && (
          <div
            className={cx({
              "text-gray-700": !isEditMode,
              "text-blue-300": isEditMode,
            })}
          >
            {withDnd && !isSummary && (
              <div>
                <Icon
                  iconName="grip-dots-vertical"
                  className="icon-12 text-reset cursor-pointer"
                />
              </div>
            )}
          </div>
        )}
        {hasChildren && (
          <Icon
            onClick={handleToggle}
            iconName={isOpen ? "fa-chevron-down" : "fa-chevron-right"}
            className={cx(
              "icon-16  mx-1 cursor-pointer position-relative",
              s.folderIcon,
              {
                "text-white": isLeftSidebar,
              },
            )}
          />
        )}

        {!isWizard && !isSummary && (
          <>
            <Button
              square={true}
              color="tertiary"
              iconPrefix="trox"
              icon="plus-regular"
              iconClassName="icon-12"
              classNames={cx("mx-1 p-0_5 bg-white position-relative z-1", {
                "ms-2_5": !isIconsShown || pasteModeOnly,
              })}
              id={`addLayer${id}`}
              onClick={handleCreate}
            />
            {!isDragPreview && (
              <>
                <UncontrolledTooltip target={`addLayer${id}`} placement="top">
                  {addLayer}
                </UncontrolledTooltip>
                {typeof addLayer === "object" && (
                  <TranslateWrapper
                    tooltipId={`addLayer${id}`}
                    tooltipText={addLayer.props.tooltipText}
                    placement="bottom"
                  />
                )}
              </>
            )}
          </>
        )}
        {isEditMode ? (
          <div
            className={cx(styles.inputWrapper, "d-flex align-items-center")}
            ref={inputRef}
          >
            <Input
              className="ms-2 my-1 fw-bolder text-gray-700 ps-2 fs-md"
              type="text"
              bsSize="sm"
              value={inputValue}
              onChange={handleInputChange}
              maxLength={30}
              onKeyDown={handleInputKeyDown}
              data-testid="folder-title-input"
            />
            <div
              className={cx(
                styles.inputLengthLabel,
                "text-gray-400 d-flex flex-nowrap",
              )}
            >
              <span className="fw-bolder">{inputValue.length}</span>
              <span>&nbsp;/ {30}</span>
            </div>
          </div>
        ) : (
          <>
            <span
              onClick={handleToggleEditMode}
              className={cx("mx-1 fw-bold fs-md", s.title, {
                "cursor-pointer": !isRootNode && !isWizard,
              })}
              data-testid="folder-title"
            >
              {title}
            </span>
            {isWizardWithLink && (
              <WizardLink
                params={{
                  projectId,
                  nodeId: id,
                }}
              />
            )}
          </>
        )}
      </FolderWrapper>
    );
  },
);
