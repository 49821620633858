export const PROJECTS_PER_PAGE = 10;

export const unitOptions = [
  { value: "de_m3ph", label: "DE_M3PH" },
  {
    value: "de_lps",
    label: "DE_LPS",
  },
  { value: "de_us", label: "DE_US" },
];

export const MAX_PROJECT_TITLE_LENGTH = 100;

export const resultsNumberOptions = [
  { label: "10", value: 10 },
  { label: "50", value: 50 },
  { label: "100", value: 100 },
];
