// when AB provides single zip file for all reports we can get rid of setTimeout
export const downloadFileByURL = (url) => {
  if (url) {
    const link = document.createElement("a");
    link.href = url;
    document.body.appendChild(link);
    setTimeout(() => link.click(), 3000);
    document.body.removeChild(link);
  }
};
