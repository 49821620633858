import { gql } from "@urql/core";

const DepartmentListQry = gql`
  query ($myDepartments: Boolean!, $page: Int!, $size: Int!) {
    getDepartments(myDepartments: $myDepartments, page: $page, size: $size) {
      results {
        name
        id
      }
      total
      limit
      count
      offset
    }
  }
`;

export { DepartmentListQry };
