import { gql } from "@urql/core";

const NodeCreateMut = gql`
  mutation (
    $input: NewProjectNode!
    $projectId: String!
    $referencePosition: Int
  ) {
    createProjectNode(
      projectId: $projectId
      referencePosition: $referencePosition
      input: $input
    ) {
      id
      title
      kind
      referencePosition
      parentId
      systemPath
      localPath
    }
  }
`;

// used also for moving the node (and its children) from one position to another
const NodeUpdateMut = gql`
  mutation ($input: UpdatedProjectNode, $id: String!, $referencePosition: Int) {
    updateProjectNode(
      id: $id
      referencePosition: $referencePosition
      input: $input
    ) {
      id
      title
      kind
      configurations
      referencePosition
      parentId
      nodes
      systemPath
      localPath
    }
  }
`;

const NodeDeleteMut = gql`
  mutation ($id: String!) {
    deleteProjectNode(id: $id) {
      id
      title
      kind
      referencePosition
      parentId
      nodes
      systemPath
      localPath
    }
  }
`;

const NodeCopyMut = gql`
  mutation (
    $input: CopyProjectNode
    $referencePosition: Int
    $id: String!
    $destinationId: String
  ) {
    copyProjectNode(
      id: $id
      destinationId: $destinationId
      referencePosition: $referencePosition
      input: $input
    ) {
      id
      title
      kind
      referencePosition
      configurations
      parentId
      nodes
      systemPath
      localPath
    }
  }
`;

export { NodeCreateMut, NodeUpdateMut, NodeDeleteMut, NodeCopyMut };
