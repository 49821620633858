import { addConfigurationToNode } from "api/cacheUtils/treeNodeUpdates/utils";

export const addConfig = (projectTree, newConfiguration) => {
  const { nodeId } = newConfiguration;

  addConfigurationToNode({
    nodes: projectTree,
    newConfiguration,
    parentId: nodeId,
  });
  return projectTree;
};
