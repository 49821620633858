export const actionBeforeSendRequestAddConfiguration = ({
  operation,
  queue,
}) => {
  const shouldBeInQueue = operation.variables?.nodeId?.startsWith("temp__");

  if (shouldBeInQueue) {
    queue.add(operation);
  }
};
