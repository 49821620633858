import React from "react";
import { Spinner as ReactstrapSpinner } from "reactstrap";

export const Spinner = ({ size = "lg", color = "primary", classNames }) => {
  const sizes = {
    lg: "4rem",
    md: "3rem",
    sm: "2rem",
    xs: "1.5rem",
    xxs: "1.2rem",
  };

  return (
    <ReactstrapSpinner
      className={classNames}
      style={{
        width: sizes[size],
        height: sizes[size],
        color,
      }}
    />
  );
};
