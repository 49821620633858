// import { useMemo } from "react";
// import { useQuery } from "urql";
// import dummyData from "../objs/_SameObjs/UserList/DummyData.json";
// // import { UserStatusesQry } from "api/queries";

// export const useUserStatuses = (pause = false) => {
//   //   const [{ data }] = useQuery({
//   //     query: UserStatusesQry,
//   //     context: useMemo(() => ({ requestPolicy: "cache-first" }), []),
//   //     pause,
//   //   });

//   const { userStatuses } = dummyData || {};
//   return userStatuses;
// };
