import React, { useMemo, useRef } from "react";
import { CustomDragLayer } from "components/CustomDragLayer/CustomDragLayer";
import TreeNode from "components/ProjectTree/TreeNode";

import styles from "components/ProjectTree/ProjectTree.module.scss";
import { useProjectTreeContext } from "providers";
import { Folder } from "components/ProjectTree/Folder/Folder";

export const ProjectTreeContent = React.memo(({ project }) => {
  const { withDnd, isWizardObjsOmit } = useProjectTreeContext();
  const { projectTree, title } = project;

  const ulRef = useRef(null);

  const projectTreeToDisplay = useMemo(
    () =>
      isWizardObjsOmit
        ? projectTree?.filter(
            (item) =>
              !["wizardFireprotection", "wizardRoomcontrol"].includes(
                item.kind,
              ),
          )
        : projectTree,
    [isWizardObjsOmit, projectTree],
  );

  return (
    <>
      {projectTreeToDisplay && (
        <div className={styles.nodeTreeWrapper}>
          <ul ref={ulRef}>
            {withDnd && (
              <CustomDragLayer
                render={(props) => (
                  <Folder
                    hasChildren={
                      props.nodes?.length || props.configurations || null
                    }
                    {...props}
                  />
                )}
              />
            )}
            {projectTreeToDisplay.map((node) => (
              <TreeNode
                key={node.id || "rooot"}
                {...node}
                projectName={title}
              />
            ))}
          </ul>
        </div>
      )}
    </>
  );
});
