import { getPageObjAsync } from "utils";
import { login, logout, refresh } from "api/ABrest/ABrest";

export const authIndicatorFlag = "isUserLoggedIn";
export const currentUserIdentifier = "currentUser";

export const redirectToLoginPage = async () => {
  localStorage.removeItem(authIndicatorFlag);
  try {
    const res = await getPageObjAsync("LoginPage");
    // not Scrivito.navigate because urqlClient needs full reload
    // see urql issue with clear store: https://github.com/urql-graphql/urql/issues/2511
    window.location.href = `${window.location.origin}/${res.id()}`;
  } catch (err) {
    console.error(err);
  }
};

export const loginReq = async (email, password) => {
  const res = await login(email, password);
  if (!res.ok) {
    throw new Error(`HTTP error! Status: ${res.status}`);
  } else {
    localStorage.setItem(authIndicatorFlag, "true");
  }
  return res;
};

export const logoutReq = async () => {
  const res = await logout();
  if (res.ok) {
    localStorage.removeItem(authIndicatorFlag);
    localStorage.removeItem("currentUser");
    localStorage.removeItem("department");
    await redirectToLoginPage();
  } else {
    throw new Error(`HTTP error! Status: ${res.status}`);
  }
};

export const refreshReq = async (wait = 0) => {
  try {
    const response = await refresh();
    if (response.status !== 200) {
      localStorage.removeItem(authIndicatorFlag);
      localStorage.removeItem("currentUser");
      await redirectToLoginPage();
      return false;
    }
    if (response.status === 200) {
      return true;
    }
  } catch (err) {
    console.error(err);
    await refreshReq(wait ? wait * 2 : 1000);
  }
};
