import { gql } from "@urql/core";

const CreateUpdateProjectReusableFrag = gql`
  fragment projectAttrs on Project {
    constructionTitle
    projectNumber
    description
    id
    status
    title
    units
    country
    lastEditBy {
      id
      fullName
      email
      lastName
      firstName
    }
  }
`;

const ProjectUpdateMut = gql`
  mutation ($input: UpdateProjectInput!, $id: String!) {
    updateProject(id: $id, input: $input) {
      sharedWith {
        id
        fullName
        email
        lastName
        firstName
      }
      ...projectAttrs
    }
  }
  ${CreateUpdateProjectReusableFrag}
`;

const ProjectCreateMut = gql`
  mutation (
    $constructionTitle: String
    $projectNumber: String
    $country: String!
    $description: String
    $status: String
    $title: String!
    $units: String!
    $language: String
    $departmentId: String
  ) {
    createProject(
      constructionTitle: $constructionTitle
      projectNumber: $projectNumber
      country: $country
      description: $description
      status: $status
      title: $title
      units: $units
      language: $language
      departmentId: $departmentId
    ) {
      createdAt
      updatedAt
      ...projectAttrs
    }
  }
  ${CreateUpdateProjectReusableFrag}
`;

const ProjectStatusUpdateMut = gql`
  mutation ($input: UpdateProjectInput!, $id: String!) {
    updateProject(id: $id, input: $input) {
      id
      status
    }
  }
`;

const MultipleProjectsDeleteMut = gql`
  mutation ($ids: [String!]!) {
    deleteProjects(ids: $ids) {
      deletedIds
      undeletedIds
      errors
    }
  }
`;

const DuplicateProjectMut = gql`
  mutation (
    $id: String!
    $languageCode: String!
    $recipientEmail: String!
    $notes: String
  ) {
    duplicateProject(
      id: $id
      languageCode: $languageCode
      recipientEmail: $recipientEmail
      notes: $notes
    )
  }
`;

const CreateProjectReportsMut = gql`
  mutation ($input: createProjectReportsArgs) {
    createProjectReports(input: $input) {
      bomCompact {
        id
        url
      }
      projectWithSpecText {
        id
        url
      }
    }
  }
`;

export {
  ProjectCreateMut,
  ProjectUpdateMut,
  ProjectStatusUpdateMut,
  MultipleProjectsDeleteMut,
  DuplicateProjectMut,
  CreateProjectReportsMut,
};
