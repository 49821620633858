import { addNodeByParentId } from "api/cacheUtils/treeNodeUpdates/utils";

export const nodeCreate = (projectTree, newNode, args, info) => {
  const { parentId } = info?.optimistic ? args.input : newNode;

  if (parentId === null) {
    projectTree[0].nodes.push(newNode);
    return projectTree;
  }

  addNodeByParentId({ nodes: projectTree, parentId, newNode });
  return projectTree;
};
