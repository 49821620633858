import React, { useContext, useEffect } from "react";
import { useQuery } from "urql";
import * as Scrivito from "scrivito";
import { authIndicatorFlag, currentUserIdentifier } from "api/auth";
import { UserDataQry } from "api/queries";

const UserContext = React.createContext({});

export const UserContextProvider = Scrivito.connect(({ children }) => {
  const isUserLoggedIn = localStorage.getItem(authIndicatorFlag);
  const currentUserUnparsed = localStorage.getItem(currentUserIdentifier);
  let currentUser;
  try {
    currentUser = currentUserUnparsed ? JSON.parse(currentUserUnparsed) : null;
  } catch (e) {
    console.error("Error parsing JSON:", e);
    currentUser = null;
  }
  const [{ data, fetching }, fetchUserData] = useQuery({
    query: UserDataQry,
    requestPolicy: "cache-first",
    pause: true,
  });

  const currentPage = Scrivito.currentPage();

  useEffect(() => {
    if (
      isUserLoggedIn &&
      !currentUser &&
      currentPage &&
      currentPage.objClass() !== "LoginPage" &&
      !fetching
    ) {
      fetchUserData();
    }
  }, [currentPage, currentUser, fetchUserData, fetching, isUserLoggedIn]);

  if (
    (data?.currentUser && !currentUser) ||
    (data?.currentUser &&
      currentUser &&
      data.currentUser.fullName !== currentUser.fullName)
  ) {
    localStorage.setItem("currentUser", JSON.stringify(data.currentUser));
  }

  return (
    <UserContext.Provider
      value={{
        isUserLoggedIn,
        userFullName:
          currentUser?.fullName || data?.currentUser?.fullName || null,
        userEmail: currentUser?.email || data?.currentUser?.email || null,
        tenant:
          currentUser?.tenant?.toLowerCase() ||
          data?.currentUser?.tenant?.toLowerCase() ||
          null,
        scrivitoTennant:
          currentUser?.tenant || data?.currentUser?.tenant || null,
      }}
    >
      {children}
    </UserContext.Provider>
  );
});

export const useUserContext = () => useContext(UserContext);
