import React, { useCallback, useEffect, useMemo, useState } from "react";
import * as Scrivito from "scrivito";
import { Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import NiceModal from "@ebay/nice-modal-react";
import cx from "classnames";
import {
  formatPresentation,
  getProductData,
  getSelectedItems,
  handleMultipleFilesDownload,
  handleSingleFileDownload,
  formatDisplayedName,
  formatFileDescription,
  addProductInfosToSublist,
  translate,
} from "utils";
import { items } from "variables";
import { useAppBaseContext } from "providers";
import { useMutation } from "urql";
import { useFetchManualsForProduct } from "hooks";
import {
  createProductMut,
  createProductReport,
  createProductXBIMReport,
} from "api/mutations";
import { useProjectContext } from "providers/ProjectProvider";

import { Button } from "../../Button";
import { Icon } from "../../Icon/Icon";
import { ModalCheckboxesBlock } from "./ModalCheckboxesBlock";
import s from "./ProjectDownloadModal.module.scss";

export const SingleProductDownloadModal = NiceModal.create(
  Scrivito.connect(({ modalSubtitle, orderCode, presentation }) => {
    const modal = NiceModal.useModal();
    if (!orderCode) {
      return null;
    }

    const { documents } = useFetchManualsForProduct(
      orderCode?.seriesId.toString(),
    );

    const { lang } = useAppBaseContext();
    const { units } = useProjectContext();

    const [itemsData, setItemsData] = useState(items);
    const [reportsInProgress, setReportsInProgress] = useState(false);

    const [, exeCreateProduct] = useMutation(createProductMut);
    const [, executeCreateProductReport] = useMutation(createProductReport);
    const [, executeCreateProductXBIMReport] = useMutation(
      createProductXBIMReport,
    );

    useEffect(() => {
      const updatedItems = items.map((item) => {
        if (
          item.title === "PRODUCT_INFORMATION" &&
          documents.productInfo.length > 0
        ) {
          return {
            ...item,
            content: [
              ...item.content,
              ...addProductInfosToSublist(item, documents.productInfo),
            ].map((contentItem) => ({
              ...contentItem,
              fileName: formatDisplayedName(item, presentation),
              fileDescription: formatFileDescription(contentItem),
            })),
          };
        }

        return {
          ...item,
          content: [...item.content].map((contentItem) => ({
            ...contentItem,
            fileName: formatDisplayedName(item, presentation),
            fileDescription: formatFileDescription(item),
          })),
        };
      });

      setItemsData(updatedItems);
    }, [presentation, documents]);

    const resetCheckedItems = itemsData.map((item) => ({
      ...item,
      checked: false,
      content: item.content
        ? item.content.map((subItem) => ({
            ...subItem,
            checked: false,
          }))
        : item.content,
    }));

    const handleUpdateAll = useCallback(
      (checked) => {
        const hasNoManuals = documents.manuals.length === 0;
        const hasNoCertificates = documents.certificates.length === 0;

        setItemsData((prevData) =>
          prevData?.map((item) => {
            const newChecked =
              (item.title === "PRODUCT_CONFIGURATOR_MANUALS" && hasNoManuals) ||
              (item.title === "PRODUCT_CONFIGURATOR_CERTIFICATE" &&
                hasNoCertificates)
                ? false
                : checked;
            const updatedSublist = item.content
              ? item.content.map((subItem) => ({
                  ...subItem,
                  checked: newChecked,
                }))
              : item.content;

            return {
              ...item,
              checked: newChecked,
              content: updatedSublist,
            };
          }),
        );
      },
      [documents],
    );

    const handleSelectAll = useCallback(
      () => handleUpdateAll(true),
      [handleUpdateAll],
    );

    const handleClearAll = useCallback(
      () => handleUpdateAll(false),
      [handleUpdateAll],
    );

    const handleSubmit = useCallback(async () => {
      setReportsInProgress(true);
      const productData = await getProductData(
        exeCreateProduct,
        orderCode,
        lang,
      );
      const formattedPresentation = formatPresentation(
        productData.product.ordercode.presentation,
      );
      const selectedItems = getSelectedItems(itemsData);
      if (selectedItems.length === 1) {
        await handleSingleFileDownload(
          selectedItems[0],
          productData,
          formattedPresentation,
          units,
          lang,
          orderCode,
          executeCreateProductXBIMReport,
          executeCreateProductReport,
          documents,
        );
      } else {
        await handleMultipleFilesDownload(
          selectedItems,
          productData,
          formattedPresentation,
          units,
          lang,
          orderCode,
          executeCreateProductXBIMReport,
          executeCreateProductReport,
          documents,
        );
      }
      setReportsInProgress(false);
      setItemsData(resetCheckedItems);
    }, [
      exeCreateProduct,
      itemsData,
      lang,
      units,
      orderCode,
      resetCheckedItems,
      documents,
      executeCreateProductReport,
      executeCreateProductXBIMReport,
    ]);

    const checked = useMemo(
      () =>
        itemsData.reduce((acc, item) => {
          if (item.checked) {
            acc.push(item);
          }

          if (item.content) {
            const checkedSublistItems = item.content.filter(
              (subItem) => subItem.checked,
            );
            acc.push(...checkedSublistItems);
          }

          return acc;
        }, []),
      [itemsData],
    );

    return (
      <Modal
        isOpen={modal.visible}
        centered={true}
        size="xl"
        toggle={modal.hide}
        scrollable={true}
      >
        <ModalHeader
          toggle={modal.hide}
          tag="div"
          className="d-flex align-items-start pb-0"
        >
          <h3 className="text-uppercase my-xxl-1 lh-32">
            {translate("DOWNLOAD_OPTIONS")}
          </h3>
          <h4 className="mb-0 lh-24">{modalSubtitle}</h4>
        </ModalHeader>
        <ModalBody
          className={cx("d-flex row pb-8 pt-xs-6 ", s.modalBodyScrollbar)}
        >
          <ModalCheckboxesBlock
            data={itemsData}
            setData={setItemsData}
            blockTitle=""
            showProducts={false}
            seriesId={orderCode?.seriesId.toString()}
          />
        </ModalBody>
        <ModalFooter className="pt-0">
          <Button
            data-testid="DownloadModal_select-all-btn"
            onClick={handleSelectAll}
            className="me-3"
            color="secondary"
            outline
          >
            {translate("SELECT_ALL")}
          </Button>
          <Button
            data-testid="DownloadModal_clear-selection-btn"
            onClick={handleClearAll}
            className="me-3"
            color="secondary"
            outline
            disabled={!checked.length}
          >
            {translate("CLEAR_SELECTION")}
          </Button>
          <Button
            data-testid="DownloadModal_download-btn"
            className="d-inline-block text-white"
            onClick={handleSubmit}
            color="primary"
            disabled={checked.length === 0 || reportsInProgress}
          >
            <Icon
              prefix="trox"
              iconName="arrow-down-bracket-regular"
              className="me-1"
            />
            {translate("DOWNLOAD_DATA")}
          </Button>
        </ModalFooter>
      </Modal>
    );
  }),
);
