import { gql } from "@urql/core";

const editTopologyConfigurationMut = gql`
  mutation (
    $id: String!
    $nodeId: String!
    $pimId: String!
    $productName: String!
    $orderCode: OrderCodeInput!
    $presentation: String!
  ) {
    editTopologyConfiguration(
      id: $id
      nodeId: $nodeId
      pimId: $pimId
      productName: $productName
      orderCode: $orderCode
      presentation: $presentation
    ) {
      topologyConfiguration {
        id
        nodeId
        pimId
        presentation
        productName
      }
      topology
    }
  }
`;

const deleteTopologyItemMut = gql`
  mutation ($wizardNodeId: String!, $topologyNodeId: String!) {
    deleteTopologyNode(
      wizardNodeId: $wizardNodeId
      topologyNodeId: $topologyNodeId
    ) {
      topologyNodeId
    }
  }
`;

const invokeTopologyActionsMut = gql`
  mutation (
    $wizardNodeId: String!
    $topologyNodeId: String!
    $action: String!
    $targetIds: [String]
    $parameters: [CustomJson]
    $topology: CustomJson
  ) {
    invokeTopologyAction(
      wizardNodeId: $wizardNodeId
      topologyNodeId: $topologyNodeId
      action: $action
      parameters: $parameters
      targetIds: $targetIds
      topology: $topology
    )
  }
`;

export {
  editTopologyConfigurationMut,
  deleteTopologyItemMut,
  invokeTopologyActionsMut,
};
