export const sharedGeneralTranslations = {
  ADD_TO_FAVOURITE: "Add to favourite",
  BACK: "Back",
  CANCEL: "Cancel",
  COLLAPSE_CONTROL_HIDE_ALL: "Collapse all",
  COLLAPSE_CONTROL_SHOW_ALL: "Show all",
  CONFIGURE: "Configure",
  CONTINUE: "Continue",
  COPY: "Copy",
  COPY_PROPERTIES: "Copy Properties",
  DELETE: "Delete",
  DETAILS: "Details",
  DOWNLOAD: "Download",
  DUPLICATE: "Duplicate",
  EDIT: "Edit",
  ERROR_LOGOUT: "Ein Fehler ist waehrend des Logouts aufgetreten",
  ERROR_NO_IMAGE: "Please, set an image.",
  ERROR_OCCURED: "Ein Fehler ist aufgetreten",
  ERROR_PAGE_NOT_FOUND: "Fehler: 404 - Seite nicht gefunden",
  ERROR_PAGE_NOT_FOUND_TEXT:
    "Leider ist die von Ihnen aufgerufene Seite auf diesem Server nicht vorhanden.",
  ERROR_PAGE_NOT_FOUND_TITLE: "SEITE NICHT GEFUNDEN",
  FILE_DEFAULT: "Dokument",
  FILE_EXCELL: "Excel Dokument",
  FILE_PDF: "PDF Dokument",
  FILE_SIZE: "Größe",
  FILE_TYPE: "Dateiformat",
  FILE_WORD: "Word Dokument",
  FILE_ZIP: "ZIP Dokument",
  FILTER: "Filter",
  OPEN_PDF: "Open PDF",
  HIDE_SYSTEM_INFORMATION: "Hide System Information",
  IS_LOADING_TEXT: "Please wait...",
  LANG_DE: "Deutsch",
  LANG_EN: "Englisch",
  LS_PRODUCTS_FILTER_OPTIONS: "Filter Options",
  LS_PRODUCTS_QUICK_SELECTION: "Quick Selection",
  MAX: "max",
  MINIMIZE: "Minimize",
  MORE_ACTIONS: "More actions",
  NAME: "Name",
  NOT_SHARED: "Not shared",
  NO_DATA: "No data",
  PAGE_NO_PAGE_TITLE: "Kein Titel",
  PASTE_PROPERTIES: "Paste Properties",
  PREVIOUS: "Previous",
  SHARE: "Share",
  SHARED: "Shared",
  SHOW_PRICE: "Show Price",
  SHOW_SYSTEM_INFORMATION: "Show System Information",
  STEP: "Step",
  SYSTEM_INFORMATION: "System Information",
  UNKNOWN: "Unknown",
  SAVE: "Save",
  COPY_TEXT: "Copy Text",
  COPIED: "Copied",
  CREATE: "Create",
  ACCOUNT_SETTINGS: "Account settings",
  CHANGE_PASSWORD: "Change password",
  TROX_HOMEPAGE: "TROX Homepage",
  MY_PROJECTS: "My Projects",
  WELCOME: "Welcome",
  MY_CONTACTS: "My Contacts",
  LEAVE_TROX_CONFIGURATOR: "Leave TROX CONFIGURATOR",
  SELECT: "Select",
  UNDER_CONSTRUCTION: "Under Construction",
  PROJECT_STRUCTURE: "Project Structure",
  TROX_TEMPLATES: "TROX Templates",
  CUSTOM_LIBRARY: "Custom Library",
  SYSTEM_DESIGN: "System Design",
  ITEMS_PER_PAGE: "Items per page",
  PROJECTS_PER_PAGE: "Projects per Page",
  NO_DOCUMENTS_AVAILABLE: "There are no documents available at the moment",
};
