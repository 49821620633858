import { useState, useEffect } from "react";
import * as Scrivito from "scrivito";
import { useAppBaseContext } from "providers/AppBaseProvider";
import { useUserContext } from "providers/UserContextProvider";

export const useFetchManualsForProduct = (epfId = null) => {
  const [documents, setDocuments] = useState({
    certificates: [],
    productInfo: [],
    manuals: [],
  });

  const { lang } = useAppBaseContext();
  const { scrivitoTennant } = useUserContext();

  useEffect(() => {
    async function loadManuals() {
      const obj = await Scrivito.load(() =>
        Scrivito.Obj.where("_objClass", "equals", "ProductSeries")
          .and("epfId", "equals", epfId)
          .and("categoryLanguages", "equals", lang)
          .and("categoryClients", "equals", scrivitoTennant)
          .and("categoryOwner", "equals", "SC")
          .first(),
      );

      if (obj) {
        const docs = await Scrivito.load(() => obj.get("documents"));
        const categorizedDocs = {
          certificates: [],
          productInfo: [],
          manuals: [],
        };
        docs.forEach((doc) => {
          const category = doc.get("categoryDownloadType");
          if (category === "certificates") {
            categorizedDocs.certificates.push(doc);
          } else if (category === "product_info") {
            categorizedDocs.productInfo.push(doc);
          } else if (category === "manuals") {
            categorizedDocs.manuals.push(doc);
          }
        });

        setDocuments(categorizedDocs);
      } else {
        setDocuments({
          certificates: [],
          productInfo: [],
          manuals: [],
        });
      }
    }

    loadManuals();
  }, [epfId, lang, scrivitoTennant]);

  return { documents };
};
