import React, { useCallback, useMemo } from "react";
import * as Scrivito from "scrivito";
import { LinkButtonWithChevron } from "components/LinkButtonWithChevron";
import cx from "classnames";
import { useConfiguratorPopupContext } from "providers";
import { LeftSidebarSubNavHeader } from "components/LeftSidebar/LeftSidebarSubNavHeader";
import { getSubnavItemTitle } from "utils";

import s from "components/LeftSidebar/LeftSidebar.module.scss";

const singleObjClass = [
  "ProjectConfigurator",
  "ProjectItem",
  "ProjectItemSame",
];
const scrollToTop = () => {
  const divToScroll = document.querySelector("#application");
  if (divToScroll) {
    divToScroll.scrollTo({
      top: divToScroll.offsetTop,
      behavior: "smooth",
    });
  }
};
export const FirstSubnavItem = ({
  referenceObj,
  projectId,
  seriesId,
  setActiveButton,
}) => {
  const objClass = referenceObj.objClass();
  const linkTitle = getSubnavItemTitle(objClass);
  const { isUpdateButtonActive, showConfiguratorPopup } =
    useConfiguratorPopupContext();
  const isDisabled = useMemo(
    () =>
      (!projectId && singleObjClass.some((item) => item === objClass)) ||
      (objClass === "ProductConfigurator" && !seriesId),
    [projectId, objClass, seriesId],
  );

  const urlParams = useMemo(() => {
    if (objClass === "ProjectList") {
      return projectId ? { projectId } : null;
    }
    if (objClass === "ProjectListSame") {
      return projectId ? { projectId } : null;
    }
    if (objClass === "ProductConfigurator") {
      return { projectId, seriesId };
    }
    return { projectId };
  }, [objClass, seriesId, projectId]);

  const handleClick = useCallback(() => {
    const navigate = () => {
      scrollToTop();
      setActiveButton(null);
      Scrivito.navigateTo(referenceObj, urlParams);
    };
    if (isUpdateButtonActive) {
      showConfiguratorPopup(navigate);
    } else {
      navigate();
    }
  }, [
    setActiveButton,
    referenceObj,
    urlParams,
    isUpdateButtonActive,
    showConfiguratorPopup,
  ]);

  return (
    <LinkButtonWithChevron
      disabled={isDisabled}
      params={urlParams}
      className={cx(s.leftSidebarSubNavLink, {
        [s.leftSidebarSubNavLinkDisabled]: isDisabled,
      })}
      onClick={handleClick}
    >
      <LeftSidebarSubNavHeader referenceObj={referenceObj} title={linkTitle} />
    </LinkButtonWithChevron>
  );
};
