import React, { createContext, useContext, useEffect } from "react";
import * as Scrivito from "scrivito";
import { useQuery } from "urql";
import { ProjectDataQry } from "api/queries";
import { useStateLoaderContext } from "./PageLoadStateProvider";

export const ProjectContext = createContext({});
export const useProjectContext = () => useContext(ProjectContext);

export const ProjectContextProvider = Scrivito.connect(({ children }) => {
  const { projectId } = Scrivito.currentPageParams();
  const { setIsLoaderXCircle } = useStateLoaderContext();
  const [{ data: projectData }, fetchProjectData] = useQuery({
    query: ProjectDataQry,
    variables: {
      id: projectId || "",
    },
    pause: true,
  });

  useEffect(() => {
    if (projectId) {
      fetchProjectData();
    }
  }, [fetchProjectData, projectId, setIsLoaderXCircle]);

  return (
    <ProjectContext.Provider
      value={{
        projectId,
        title: projectData?.project?.title || "",
        units: projectData?.project?.units,
      }}
    >
      {children}
    </ProjectContext.Provider>
  );
});
