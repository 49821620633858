import React from "react";
import * as Scrivito from "scrivito";

export const ProjectDownloadAccordionItemSublist = Scrivito.connect(
  ({ listOfDownloads }) => (
    <ul className="list-unstyled">
      {listOfDownloads?.map((contentItem) => (
        <li key={contentItem.id}>
          <div className="d-flex justify-content-between align-items-center">
            <span className="text-gray-400">{contentItem.fileName}</span>
          </div>
        </li>
      ))}
    </ul>
  ),
);
