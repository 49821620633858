import { useCallback, useEffect, useRef, useState } from "react";
import * as Scrivito from "scrivito";
import { getHomepage } from "utils";

export const useProductOverview = ({ page, lang }) => {
  const [allProducts, setAllProducts] = useState(null);
  const [productsContent, setProductsContent] = useState([]);
  const [categoryHeadline, setCategoryHeadline] = useState("");
  const [productListObj, setProductListObj] = useState(page);
  const [productConfiguratorObj, setProductConfiguratorObj] = useState(null);
  const [defaultImage, setDefaultImage] = useState();
  const [isImageFetching, setImageFetching] = useState(false);
  const imageRef = useRef();

  const { productCategoryId, projectId } = Scrivito.currentPageParams();

  useEffect(() => {
    const getProductObjsData = async () => {
      const productListData = await Scrivito.load(() => {
        const homepage = getHomepage();

        const productList =
          homepage.get("productListPage") || Scrivito.currentPage();

        const productConfigurator =
          homepage.get("productConfiguratorPage") || Scrivito.currentPage();

        return {
          listObj: productList,
          configuratorObj: productConfigurator,
          defaultProductImage: productList.get("defaultProductImage"),
        };
      });
      !productListObj && setProductListObj(productListData.listObj);
      !productConfiguratorObj &&
        setProductConfiguratorObj(productListData.configuratorObj);
      !defaultImage && setDefaultImage(productListData.defaultProductImage);
      imageRef.current = productListData.defaultProductImage;
    };
    getProductObjsData();
  }, [lang, productListObj, productConfiguratorObj, defaultImage]);

  const addImagesToProduct = useCallback(async (nodes = []) => {
    const pimIds = nodes.map((i) => i.pimId);
    const imageList = await Scrivito.load(() =>
      Scrivito.Obj.where("_objClass", "equals", "Image")
        .and("pimId", "equals", pimIds)
        .take(),
    );

    return nodes.map((node) => {
      const image =
        imageList.find((i) => i.get("pimId") === node.pimId) ||
        imageRef.current;
      return { ...node, image };
    });
  }, []);

  const imagesHandler = useCallback(
    async (activeCategory = {}) => {
      const { nodes } = activeCategory;
      if (nodes) {
        setImageFetching(true);
        const imagesOfNode = await addImagesToProduct(nodes);
        await setProductsContent(imagesOfNode);
        await setImageFetching(false);
      }
    },
    [addImagesToProduct],
  );

  return {
    allProducts,
    setAllProducts,
    productsContent,
    setProductsContent,
    categoryHeadline,
    setCategoryHeadline,
    productListObj,
    productConfiguratorObj,
    defaultImage,
    addImagesToProduct,
    imagesHandler,
    productCategoryId,
    projectId,
    isImageFetching,
  };
};
