import React from "react";
import * as Scrivito from "scrivito";
import { Field, Form, Formik } from "formik";
import { ModalBody, ModalFooter } from "reactstrap";
import { translate } from "utils";
import { Button } from "components/Button";
import { FormSelect, TextInput } from "components/Inputs";
import cx from "classnames";
import s from "./UserDetailsModal.module.scss";

export const UserDetailsForm = Scrivito.connect(
  ({
    handleSubmit,
    initialValues,
    isUpdate,
    countriesOptions,
    optionsList,
    group,
  }) => (
    <Formik
      initialValues={initialValues}
      onSubmit={handleSubmit}
      enableReinitialize
      validateOnBlur={false}
    >
      <Form>
        <ModalBody className="pt-2_5 d-flex row">
          <div className="col-12 col-lg-6 flex-grow-1">
            <Field
              component={TextInput}
              name="Name"
              autoFocus={true}
              data-recording-disable={true}
              label="Name*"
            />
            <Field
              component={TextInput}
              name="Email"
              autoFocus={true}
              data-recording-disable={true}
              label="Email*"
            />
            <Field
              component={TextInput}
              name="Phone Number"
              autoFocus={true}
              data-recording-disable={true}
              label="Phone Number"
            />
            <Field
              component={TextInput}
              name="Position"
              wrapperClassName="mt-3"
              autoFocus={true}
              data-recording-disable={true}
              label="Position"
              tooltipText="tooltipText"
            />
            <Field
              component={TextInput}
              name="Company"
              wrapperClassName="mt-3"
              autoFocus={true}
              data-recording-disable={true}
              label="Company"
              tooltipText="tooltipText"
            />
            <Field
              component={FormSelect}
              name="country"
              wrapperClassName={cx(s.fieldWithoutMarginBottom, "mt-3")}
              autoFocus={true}
              data-recording-disable={true}
              label="Role"
              tooltipText="tooltipText"
              options={countriesOptions}
            />
          </div>

          <div className="col-12 col-lg-6 flex-grow-1">
            <Field
              component={FormSelect}
              name="status"
              autoFocus={true}
              data-recording-disable={true}
              label="Status"
              tooltipText="tooltipeText"
              options={optionsList}
            />

            <Field
              component={FormSelect}
              name="status*"
              autoFocus={true}
              data-recording-disable={true}
              label="Group*"
              options={group}
            />
            <div className="d-flex justify-content-end mt-4">
              {translate("REQUIRED_FIELD_WITH_ASTERISK")}
            </div>
          </div>
        </ModalBody>

        <ModalFooter className="pt-0">
          <Button color="primary" type="submit">
            {isUpdate ? translate("TEXT_SAVE_CHANGES") : "Create User"}
          </Button>
        </ModalFooter>
      </Form>
    </Formik>
  ),
);
