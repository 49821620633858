import { filter, pipe, tap } from "wonka";
import {
  actionBeforeSendRequestCreateNode,
  actionOnGetCreateNodeResponse,
} from "api/exchanges/nodeCreate";
import {
  actionBeforeSendRequestCopyNode,
  actionOnGetCopyNodeResponse,
} from "api/exchanges/nodeCopy";
import { actionBeforeSendRequestAddConfiguration } from "api/exchanges/addConfiguration";

let requestInProgress = false;

export const pauseExchange = ({ forward, client }) => {
  const queue = new Set();
  const executeDeleteNodeQueue = new Set();
  const replaceKeysMap = new Map();

  return (operations$) => {
    const sharedOps$ = pipe(
      operations$,
      tap((operation) => {
        if (
          operation.context.projectPauseExchangeType &&
          operation.context.optimistic === true
        ) {
          switch (operation.context.projectPauseExchangeType) {
            case "nodeCreate":
              actionBeforeSendRequestCreateNode({
                replaceKeysMap,
                operation,
                queue,
              });
              requestInProgress = true;
              break;
            case "addConfiguration":
              actionBeforeSendRequestAddConfiguration({
                replaceKeysMap,
                operation,
                queue,
              });
              requestInProgress = true;
              break;
            case "nodeCopy":
              actionBeforeSendRequestCopyNode({
                replaceKeysMap,
                operation,
                queue,
              });
              requestInProgress = true;
              break;
            case "nodeUpdate":
              if (
                operation.variables?.id?.startsWith("temp__") ||
                operation.variables?.input?.parentId?.startsWith("temp__")
              ) {
                queue.add(operation);
              }
              break;
            case "nodeDelete":
              if (!operation.context.fromQueue && requestInProgress === true) {
                executeDeleteNodeQueue.add(operation);
              }
              break;
            default:
              console.error(
                operation.context.projectPauseExchangeType,
                "not specified",
              );
          }
        }
      }),
      filter(
        (operation) =>
          !(queue.has(operation) || executeDeleteNodeQueue.has(operation)),
      ),
    );

    return pipe(
      forward(sharedOps$),
      tap((response) => {
        if (response.operation.context.projectPauseExchangeType) {
          if (queue.size === 0) {
            requestInProgress = false;
          }
          switch (response.operation.context.projectPauseExchangeType) {
            case "nodeCreate":
              actionOnGetCreateNodeResponse({
                response,
                client,
                queue,
                replaceKeysMap,
                requestInProgress,
              });
              break;
            case "nodeCopy":
              actionOnGetCopyNodeResponse({
                response,
                client,
                queue,
                replaceKeysMap,
                requestInProgress,
              });
              break;
            case "nodeUpdate":
              break;
            case "nodeDelete":
              break;
            default:
              console.error(
                response.operation.context.projectPauseExchangeType,
                "not specified",
              );
          }

          if (queue.size === 0 && requestInProgress === false) {
            for (const deleteQueueOperation of executeDeleteNodeQueue) {
              deleteQueueOperation.context.fromQueue = true;
              client.reexecuteOperation(deleteQueueOperation);
              executeDeleteNodeQueue.delete(deleteQueueOperation);
            }
          }

          if (
            queue.size === 0 &&
            executeDeleteNodeQueue.size === 0 &&
            requestInProgress === false
          ) {
            replaceKeysMap.clear();
          }
        }
      }),
    );
  };
};
