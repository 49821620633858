import {
  HELP_BTN,
  HELP_PAGES,
  LEFT_SIDEBAR_BUTTONS,
  LEFT_SIDEBAR_BUTTONS_ARRAY_CONF,
  LIBRARY_PAGES,
  PRODUCT_PAGES,
  PROJECT_PAGES,
  PROJECT_PAGES_SAME,
  WIZARD_PAGES,
  ADMIN_PAGES,
} from "../variables";

export const pagesWithConfiguratorRightSidebar = [
  ...LIBRARY_PAGES,
  ...PRODUCT_PAGES,
];

export const pagesWithSystemConfiguratorLayout = [
  ...PROJECT_PAGES_SAME,
  ...PROJECT_PAGES,
  ...WIZARD_PAGES,
  ...HELP_PAGES,
  ...ADMIN_PAGES,
  ...pagesWithConfiguratorRightSidebar,
];

export const pagesWithGrayBg = ["Wizard", "WizardList"];

export const isPageWithGrayBg = (pageType) =>
  pagesWithGrayBg.includes(pageType);

export const isProjectSummarySidebarVisible = (pageType, projectId) =>
  projectId && pagesWithConfiguratorRightSidebar.includes(pageType);

export const isSystemConfiguratorLayout = (pageType) =>
  pagesWithSystemConfiguratorLayout.includes(pageType);

export const isConfiguratorNavVisible = (currentPage) =>
  currentPage?.get("showConfiguratorNav");

const { projBtn, prodBtn, wizBtn, libBtn, admBtnSame } =
  LEFT_SIDEBAR_BUTTONS || {};

export const getLeftSidebarButtonByPageType = (pageType) => {
  if (PROJECT_PAGES.concat(PROJECT_PAGES_SAME).includes(pageType)) {
    return projBtn;
  }
  if (PRODUCT_PAGES.includes(pageType)) {
    return prodBtn;
  }
  if (WIZARD_PAGES.includes(pageType)) {
    return wizBtn;
  }
  if (LIBRARY_PAGES.includes(pageType)) {
    return libBtn;
  }
  if (HELP_PAGES.includes(pageType)) {
    return HELP_BTN;
  }
  if (ADMIN_PAGES.includes(pageType)) {
    return admBtnSame;
  }
};

export const isLeftSidebarSubNavOpened = (activeButton) =>
  LEFT_SIDEBAR_BUTTONS_ARRAY_CONF.map((btn) => btn.title).includes(
    activeButton?.title,
  );
