import {
  updateCreateOperationWithKeysReplacement,
  updateOrLaunchOperations,
} from "api/exchanges/exchangeUtils";

export const actionBeforeSendRequestCreateNode = ({
  replaceKeysMap,
  operation,
  queue,
}) => {
  if (operation.variables?.input?.parentId === null) {
    return;
  }
  const { shouldBeInQueue } = updateCreateOperationWithKeysReplacement({
    operation,
    replaceKeysMap,
  });

  if (shouldBeInQueue) {
    queue.add(operation);
  }
};

export const actionOnGetCreateNodeResponse = ({
  response,
  queue,
  client,
  replaceKeysMap,
}) => {
  if (queue.size !== 0 && response.data?.createProjectNode) {
    const idToReplace = `temp__${response?.operation?.variables?.input?.parentId}__${response?.operation?.variables?.referencePosition}`;
    const replaceBy = response.data?.createProjectNode?.id;
    replaceKeysMap.set(idToReplace, replaceBy);

    const tempMapToUpdateQueue = new Map([[idToReplace, replaceBy]]);
    updateOrLaunchOperations({ queue, tempMapToUpdateQueue, client });
    tempMapToUpdateQueue.clear();
  }
};
