import { addNodeByParentId } from "api/cacheUtils/treeNodeUpdates/utils";

const moveNodeWithinParent = ({ nodes, targetId, newPos }) => {
  const nodeIndex = nodes.findIndex((node) => node.id === targetId);
  if (nodeIndex === -1 || nodeIndex === newPos) {
    return;
  }
  const [node] = nodes.splice(nodeIndex, 1);
  nodes.splice(newPos, 0, node);
};

const updateNodeInternal = ({ nodes, id, newTitle, node }) => {
  const recursiveUpdate = (currentNode) => {
    if (currentNode.id === id) {
      if (node) {
        Object.assign(currentNode, node);
      } else if (newTitle) {
        currentNode.title = newTitle;
      }
      return true;
    }

    return currentNode.nodes?.some(recursiveUpdate) ?? false;
  };

  return nodes.some(recursiveUpdate);
};

const removeNodeById = ({ nodes, idToRemove }) => {
  for (let i = 0; i < nodes.length; i += 1) {
    if (nodes[i].id === idToRemove) {
      return nodes.splice(i, 1)[0];
    }
    if (nodes[i].nodes) {
      const removedNode = removeNodeById({
        nodes: nodes[i].nodes,
        idToRemove,
      });
      if (removedNode) {
        return removedNode;
      }
    }
  }
  return null;
};

export const nodeUpdate = (projectTree, updatedNode, args, info) => {
  const { id, title, parentId, referencePosition } = updatedNode;

  if (args.input?.title) {
    updateNodeInternal({
      nodes: projectTree,
      id,
      newTitle: title,
    });
  }

  if (args.input?.parentId || args.input?.parentId === null) {
    const nodeToMove = removeNodeById({
      nodes: projectTree,
      idToRemove: id,
    });

    if (nodeToMove) {
      addNodeByParentId({
        nodes: projectTree,
        parentId,
        newNode: { ...nodeToMove, parentId },
        referencePosition,
      });
    }
  }

  if (args.referencePosition && !args.input?.parentId) {
    projectTree.forEach((node) => {
      if (node.nodes) {
        moveNodeWithinParent({
          nodes: node.nodes,
          targetId: id,
          newPos: referencePosition - 1,
        });
      }
    });
  }

  if (!args.input && !args.referencePosition && !info.optimistic) {
    updateNodeInternal({
      nodes: projectTree,
      id,
      node: updatedNode,
    });
  }

  return projectTree;
};
