import React from "react";
import { ProjectDownloadAccordionItem } from "./ProjectDownloadAccordionItem";
import { ProjectDownloadAccordionItemSublist } from "./ProjectDownloadAccordionItemSublist";

export const ProjectDownloadAccordionItemContent = ({
  item,
  toggleSublist,
  sublistItemOpen,
  seriesId,
  setData,
  data,
}) =>
  item.content.length ? (
    <ul className="list-unstyled">
      {item.content.map((contentItem) => (
        <ProjectDownloadAccordionItem
          open={sublistItemOpen}
          title={contentItem.title}
          key={contentItem.title}
          toggle={toggleSublist}
          checked={contentItem.checked}
          setItemsData={setData}
          seriesId={seriesId}
          itemsData={data}
          hasContent={contentItem.content?.length > 0}
          fileDescription={contentItem.fileDescription}
          contentLength={contentItem.contentLength}
          extension={contentItem.extension}
          contentUrl={contentItem.contentUrl}
        >
          <ProjectDownloadAccordionItemSublist
            listOfDownloads={contentItem.content}
          />
        </ProjectDownloadAccordionItem>
      ))}
    </ul>
  ) : (
    <ProjectDownloadAccordionItemSublist listOfDownloads={item.content} />
  );
