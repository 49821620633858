import * as Scrivito from "scrivito";
import axios from "axios";
import { isBrowserEnv } from "utils/isWindowObjAvailable";
import logo from "assets/images/trox-logo.png";
import { excludedReportsTitles } from "variables";
import {
  getFullBodyData,
  getFullHeadData,
} from "components/_ProductConfigurator/_helpers";
import { translate } from "./localsUtils";

const getOrdecodeData = (parts) =>
  parts.map((part) => part.options.map((option) => option)).flat();

export const encodeImageToBase64 = async (imageUrl) => {
  if (!imageUrl) {
    return null;
  }

  const response = await axios.get(imageUrl, { responseType: "blob" });
  const reader = new FileReader();
  reader.readAsDataURL(response.data);

  return new Promise((resolve, reject) => {
    reader.onloadend = () => {
      resolve(reader.result);
    };
    reader.onerror = reject;
  });
};

export const downloadLink = (blob, linkName) => {
  const url = URL.createObjectURL(blob);
  const link = document.createElement("a");
  link.href = url;
  link.download = linkName;
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
  URL.revokeObjectURL(blob);
};

export const getProductImageUrl = async (
  imagesList,
  imageType = "ProductPhoto",
) => {
  const productImageUrl =
    imagesList?.length &&
    (await Scrivito.load(() => {
      const productPhoto = imagesList.find((image) => image.typ === imageType);
      const productImagePimId = productPhoto ? productPhoto.pimId : null;
      const imageObject = Scrivito.Obj.where(
        "pimId",
        "equals",
        productImagePimId,
      ).first();

      if (productImagePimId && imageObject) {
        return Scrivito.urlFor(imageObject);
      }
    }));
  return productImageUrl;
};

export const getProductInfoReportBlob = async ({
  productData,
  getReportCallback,
  lang,
}) => {
  const {
    product,
    productImageList: { productImages },
    specificationText: { text },
  } = await productData;

  const productImageUrl = await getProductImageUrl(productImages);

  const reportImageUrl =
    productImages?.length &&
    (await Scrivito.load(() => {
      const reportImagePimId = productImages.find(
        (image) => image.typ !== "ProductPhoto",
      )?.pimId;
      return (
        reportImagePimId &&
        Scrivito.urlFor(
          Scrivito.Obj.where("pimId", "equals", reportImagePimId).first(),
        )
      );
    }));

  const orderCodeParts = getOrdecodeData(product.ordercode.parts);
  const logoImageUrl = isBrowserEnv() ? `${window.location.origin}${logo}` : "";

  const logoImageBase64 = await encodeImageToBase64(logoImageUrl);
  const productImageBase64 = (await encodeImageToBase64(productImageUrl)) || "";
  const reportImageBase64 = (await encodeImageToBase64(reportImageUrl)) || "";

  const strategyId = product.currentStrategyId;
  const strategy = product.availableStrategies.filter(
    (s) => s.id === strategyId,
  );

  const flattedInputs = [];
  (product.inputs || []).forEach((i) =>
    i.properties.forEach((prop) =>
      flattedInputs.push({
        name: prop.presentation,
        value: `${prop.value} ${prop.unit}`,
      }),
    ),
  );
  const flattedOutputs = [];
  (product.outputs || []).forEach((o) =>
    o.properties.forEach((prop) =>
      flattedOutputs.push({
        name: prop.presentation,
        value: `${prop.value} ${prop.unit}`,
      }),
    ),
  );

  return getReportCallback({
    languageIso2L: lang,
    viewImages: [
      {
        type: "Logo",
        image: logoImageBase64.split(",")[1],
      },
      {
        type: "Product",
        image: productImageBase64.split(",")[1],
      },
      {
        type: "Report",
        image: reportImageBase64.split(",")[1],
        imageHeader: translate(
          "PRODUCT_CONFIGURATOR_REPORT_SCHEMATIC_SIDE_VIEW_LABEL",
        ),
      },
    ],
    orderCode: {
      orderCodePresentation: product.ordercode.presentation,
      orderCodeParts: orderCodeParts
        .filter((part) => part.state === 0)
        .map((part) => ({
          name: part.title,
          value: part.presentation || part.currentSignature.value,
          description: part.currentSignature.title,
        })),
    },
    inputs: {
      headerLabel: translate("PRODUCT_CONFIGURATOR_REPORT_INPUT_LABEL"),
      ioReportList: [
        ...flattedInputs,
        {
          name: translate("PRODUCT_CONFIGURATOR_REPORT_STRATEGY"),
          value: strategy[0]?.presentation,
        },
      ],
    },
    outputs: {
      headerLabel: translate("PRODUCT_CONFIGURATOR_REPORT_RESULTS_LABEL"),
      ioReportList: flattedOutputs,
    },
    productAcousticHeaders: {
      acousticLabel: translate("PRODUCT_CONFIGURATOR_REPORT_ACOUSTING_LABEL"),
      acousticHeaders: getFullHeadData(product.outputsAcoustic),
    },
    acousticData: {
      productAcousticList: getFullBodyData(product.outputsAcoustic),
    },
    descriptionText: {
      descriptionLabel: translate("PRODUCT_CONFIGURATOR_REPORT_DESCRRIPTION"),
      description: text,
    },
    airflowPatternReport: null,
    notes: null,
    version: "EPF Version 2.10.3.5",
  }).then(({ data }) => {
    if (data && data.createProductReport?.base64Data) {
      const atobw = Uint8Array.from(
        atob(data.createProductReport?.base64Data),
        (c) => c.charCodeAt(0),
      );
      const blob = new Blob([atobw], {
        type: "application/pdf",
      });
      return { blob, type: "pdf" };
    }
  });
};

// Scrivito documents downloading
export const handleSingleScrivitoDocumentDownload = async (documentManual) => {
  const url = await Scrivito.load(() => documentManual.contentUrl());
  const documentType = documentManual.get("fileTypeDe");

  const response = await axios.get(url, {
    responseType: "blob",
  });

  return {
    blob: response.data,
    type: documentType,
  };
};

export const handleSingleManualDownload = async (documentManual) => {
  const url = await Scrivito.load(() => documentManual.contentUrl());
  const documentType = documentManual.get("fileTypeDe");
  const response = await axios.get(url, {
    responseType: "blob",
  });
  const fileBlob = new Blob([response.data], {
    type: response.headers["content-type"],
  });
  const downloadUrl = URL.createObjectURL(fileBlob);
  const link = document.createElement("a");
  link.href = downloadUrl;
  link.download = documentManual.get("title");
  document.body.appendChild(link);
  link.click();
  URL.revokeObjectURL(downloadUrl);
  document.body.removeChild(link);
  return {
    blob: response.data,
    type: documentType,
  };
};

export const handleOpenManual = (docUrl) => {
  if (docUrl) {
    const link = document.createElement("a");
    link.href = docUrl;
    link.target = "_blank";
    link.rel = "noopener noreferrer";
    link.click();
  }
};

export const importJSZip = async () => {
  const JSZip = await import("components/_Modals/ProjectDownloadModal/LazyZip");
  return JSZip.default;
};

export const fetchAndZipManuals = async (docs) => {
  const Zip = await importJSZip();
  const zip = new Zip();
  const blobsManuals = await Promise.all(
    docs.map(async (item) => {
      const contentUrl = await Scrivito.load(() => item.contentUrl());
      const response = await axios.get(contentUrl, {
        responseType: "blob",
      });
      return { blob: response.data, title: item.get("title") };
    }),
  );
  blobsManuals.forEach(({ blob, title }, index) => {
    zip.file(`${title || `manual_${index + 1}`}.pdf`, blob);
  });
  const zipBlob = await zip.generateAsync({ type: "blob" });

  return {
    blob: zipBlob,
    type: "zip",
  };
};

// SingleProductDownloadModal text formatting
export const formatPresentation = (presentation) =>
  presentation
    .replace(/\//g, "_")
    .replace(/-/g, "_")
    .replace(/³/g, "3")
    .replace(/[^a-zA-Z0-9_.]/g, "")
    .replace(/^[^a-zA-Z_]/, "_$&");

export const formatDisplayedName = (item, presentation) => {
  const formattedPresentation = formatPresentation(presentation);
  return `${formattedPresentation}_${item.title}`;
};

export const formatFileDescription = (item) => {
  const { documentCategory } = item;
  const documentCategories = {
    product_info: translate("PRODUCT_CONFIGURATOR_INFORMATION"),
    manuals: "Manual",
    certificates: "Certificate",
  };
  const getCategory = (docCategory) => documentCategories[docCategory] || null;

  return `${getCategory(documentCategory) ?? translate(item.title)}`;
};

export const formatFileName = (item, presentation) => {
  const formattedPresentation = formatPresentation(presentation);
  const translatedTitle = translate(item.title);
  const extension = item.content[0]?.extension;

  return `${formattedPresentation}_${item.title} | ${translatedTitle} | ${extension}`;
};

export const formatBytesToMB = (bytes) => {
  const megabytes = bytes / (1024 * 1024);
  const roundedMB = megabytes.toFixed(1);
  return `${roundedMB} MB`;
};

// SingleProductDownloadModal files downloading
export const getProductData = async (exeCreateProduct, orderCode, lang) => {
  const { data } = await exeCreateProduct({
    seriesId: orderCode.seriesId,
    languageIso2L: lang,
    requestUnitsSystem: "de_m3ph",
    resultUnitsSystem: "de_m3ph",
    state: { ordercodestate: orderCode.state },
    epfVersion: null,
  });

  const createProductResult = data?.createProduct?.result;
  return createProductResult;
};

export const getSelectedItems = (itemsData) =>
  itemsData.reduce((filteredSelectedItems, item) => {
    if (item.checked) {
      if (!excludedReportsTitles.includes(item.title)) {
        filteredSelectedItems.push(item.title);
      }
    }
    if (item.content) {
      const checkedSublistTitles = item.content
        .filter(
          (subItem) =>
            subItem.checked && !excludedReportsTitles.includes(subItem.title),
        )
        .map((subItem) => subItem.title);

      filteredSelectedItems.push(...checkedSublistTitles);
    }

    return filteredSelectedItems;
  }, []);

export const handleSingleFileDownload = async (
  item,
  productData,
  presentation,
  units,
  lang,
  orderCode,
  executeCreateProductXBIMReport,
  executeCreateProductReport,
  documents,
) => {
  const blobToDownload = await downloadFile(
    item,
    productData,
    units,
    lang,
    orderCode,
    executeCreateProductXBIMReport,
    executeCreateProductReport,
    documents,
  );
  downloadLink(
    blobToDownload.blob,
    `${presentation}_${item}.${blobToDownload.type}`,
  );
};

export const handleMultipleFilesDownload = async (
  selectedItems,
  productData,
  presentation,
  units,
  lang,
  orderCode,
  executeCreateProductXBIMReport,
  executeCreateProductReport,
  documents,
) => {
  const Zip = await importJSZip();
  const zip = new Zip();

  await Promise.all(
    selectedItems.map(async (item) => {
      const blobToDownload = await downloadFile(
        item,
        productData,
        units,
        lang,
        orderCode,
        executeCreateProductXBIMReport,
        executeCreateProductReport,
        documents,
      );
      zip.file(
        `${presentation}_${item}.${blobToDownload.type}`,
        blobToDownload.blob,
      );
    }),
  );

  const zipBlob = await zip.generateAsync({ type: "blob" });
  downloadLink(zipBlob, `${presentation}_reports.zip`);
};

export const findMatchingProdInfo = (title, prodInfos) => {
  for (const prodInfo of prodInfos) {
    const prodInfoTitle = prodInfo.get("title");

    if (prodInfoTitle === title) {
      return prodInfo;
    }
  }
  return null;
};

export const downloadFile = async (
  title,
  productData,
  units,
  lang,
  orderCode,
  executeCreateProductXBIMReport,
  executeCreateProductReport,
  documents,
) => {
  switch (title) {
    case "SPECIFICATION_TEXT":
      // place to add it in the Future once epf will be ready to provide it.
      return null;
    case "BIM_DATA": {
      const { data } = await executeCreateProductXBIMReport({
        systemOfUnits: units,
        languageIso2L: lang,
        countryIso2L: "de",
        seriesId: orderCode.seriesId,
        state: { ordercodestate: orderCode.state },
      });

      if (data.createProductXBIMReport?.base64Data) {
        const atobw = Uint8Array.from(
          atob(data.createProductXBIMReport?.base64Data),
          (c) => c.charCodeAt(0),
        );
        const blob = new Blob([atobw], {
          type: "application/xml",
        });

        return {
          blob,
          type: "xml",
        };
      }
      console.error("Invalid data");
      return;
    }
    case "PRODUCT_CONFIGURATOR_MANUALS":
      if (documents.manuals.length === 1) {
        return handleSingleScrivitoDocumentDownload(documents.manuals[0]);
      }
      return fetchAndZipManuals(documents.manuals);

    case "PRODUCT_CONFIGURATOR_CERTIFICATE":
      if (documents.certificates.length === 1) {
        return handleSingleScrivitoDocumentDownload(documents.certificates[0]);
      }
      return fetchAndZipManuals(documents.certificates);
    case "PRODUCT_INFORMATION_REPORT":
      return getProductInfoReportBlob({
        productData,
        getReportCallback: executeCreateProductReport,
        lang,
      });
    default:
      if (title) {
        const matchingProdInfo = findMatchingProdInfo(
          title,
          documents.productInfo,
        );

        if (matchingProdInfo) {
          return handleSingleScrivitoDocumentDownload(matchingProdInfo);
        }
      }
      console.error("unknown file type");
  }
};

export const addProductInfosToSublist = (displayedItems, scrivitoDocuments) => {
  const productInfoSublistItems =
    scrivitoDocuments.length &&
    scrivitoDocuments.map((prodInfo, index) => ({
      id: displayedItems.content.length + index + 1,
      title: prodInfo.get("title"),
      checked: false,
      extension: prodInfo.get("fileTypeDe"),
      documentCategory: prodInfo.get("categoryDownloadType"),
      contentLength: Scrivito.load(() => prodInfo.contentLength()),
      fromScrivito: true,
      contentUrl: Scrivito.load(() => prodInfo.contentUrl()),
    }));

  return productInfoSublistItems;
};
