export const addOrUpdateState = ({ array, value }) => {
  const index = array.findIndex((item) => item.id === value.id);
  return index === -1
    ? [...array, value]
    : array.map((item) => (item.id === value.id ? value : item));
};

export const getHeadData = (outputsAcoustic) =>
  outputsAcoustic[0]?.properties?.map((prop) => prop.presentation);

export const getFullHeadData = (outputsAcoustic) =>
  outputsAcoustic[0]?.properties?.map((prop) => ({
    name: prop.presentation,
    unit: prop.unit,
    displayName: null,
  }));

export const getOrderCodeState = (parts) => {
  const newOrderCodeState = [];
  for (const part of parts) {
    // TODO: isSizePart is now mandatory in topology update api. but here is no information about this attribute.
    for (const option of part.options) {
      // In some cases EPF API can be broken if we send parts with technical internal state "2".
      // Sates description: https://dev.azure.com/DEVLY-IT-Software-Development/TROX-EB-Website_v2/_wiki/wikis/TROX-System-Configurator.wiki/1132/Product-states

      part.state < 2 &&
        option.state < 2 &&
        newOrderCodeState.push({
          partKey: part.key,
          optionKey: option.key,
          signatureKey: option.currentSignature.key,
          signatureValue: option.currentSignature.value,
          separator: option.separator,
          isSizePart: false,
          signaturePresentation: option.presentation,
        });
    }
  }
  return newOrderCodeState;
};

export const getBodyData = (outputsAcoustic) =>
  outputsAcoustic?.map((out) => {
    const properties = [out.presentation];
    out.properties.map((prop) =>
      properties.push(
        prop.displayValue === "n.v."
          ? prop.displayValue
          : `${prop.displayValue} ${prop.unit}`,
      ),
    );
    return properties;
  });

export const getFullBodyData = (outputsAcoustic = []) =>
  outputsAcoustic.map((out) => {
    const { properties: prop, presentation: pres } = out;
    return {
      name: pres,
      deltaPt: `${prop?.[0]?.value || ""}`, // this notation ("") lets us to skip unneccesary column in the generated report
      lwA: `${prop?.[1]?.value || ""}`,
      soundPowerLevel63Hz: `${prop?.[2]?.value || ""}`,
      soundPowerLevel125Hz: `${prop?.[3]?.value || ""}`,
      soundPowerLevel250Hz: `${prop?.[4]?.value || ""}`,
      soundPowerLevel500Hz: `${prop?.[5]?.value || ""}`,
      soundPowerLevel1kHz: `${prop?.[6]?.value || ""}`,
      soundPowerLevel2kHz: `${prop?.[7]?.value || ""}`,
      soundPowerLevel4kHz: `${prop?.[8]?.value || ""}`,
      soundPowerLevel8kHz: `${prop?.[9]?.value || ""}`,
      lwnc: `${prop?.[10]?.value || ""}`,
      lwnr: `${prop?.[11]?.value || ""}`,
    };
  });
