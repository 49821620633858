import {
  updateCopyOperationWithKeysReplacement,
  updateOrLaunchOperations,
} from "api/exchanges/exchangeUtils";

export const actionBeforeSendRequestCopyNode = ({
  replaceKeysMap,
  operation,
  queue,
}) => {
  const { shouldBeInQueue } = updateCopyOperationWithKeysReplacement({
    operation,
    replaceKeysMap,
  });

  if (shouldBeInQueue) {
    queue.add(operation);
  }
};

export const actionOnGetCopyNodeResponse = ({
  response,
  queue,
  client,
  replaceKeysMap,
}) => {
  if (queue.size !== 0 && response?.data?.copyProjectNode) {
    const pastedItemTempId = `temp__${response?.operation?.variables?.destinationId}__${response?.operation?.variables?.referencePosition}__copy`;
    const { id, nodes } = response?.data?.copyProjectNode || {};
    replaceKeysMap.set(pastedItemTempId, id);
    const tempMapToUpdateQueue = new Map([[pastedItemTempId, id]]);

    if (nodes.length !== 0) {
      collectAllReplacementKeys({
        nodes,
        parentId: pastedItemTempId,
        tempMapToUpdateQueue,
        replaceKeysMap,
      });
    }

    updateOrLaunchOperations({ queue, tempMapToUpdateQueue, client });
    tempMapToUpdateQueue.clear();
  }
};

const collectAllReplacementKeys = ({
  nodes,
  parentId,
  tempMapToUpdateQueue,
  replaceKeysMap,
}) => {
  nodes.forEach((node) => {
    const idToReplace = `temp__${parentId}__${node.referencePosition}`;
    replaceKeysMap.set(idToReplace, node.id);
    tempMapToUpdateQueue.set(idToReplace, node.id);
    if (node.nodes && node.nodes.length > 0) {
      collectAllReplacementKeys({
        nodes: node.nodes,
        parentId: idToReplace,
        tempMapToUpdateQueue,
        replaceKeysMap,
      });
    }
  });
};
