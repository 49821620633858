import * as Scrivito from "scrivito";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { useQuery } from "urql";
import { ProjectTreeContent } from "components/ProjectTree/ProjectTreeContent";
import { ProjectDataQry } from "api/queries";
import { Clipboard } from "components/ProjectTree/Clipboard";
import { ProjectTreeContextProvider, useAppBaseContext } from "providers";
import { projectTreeContentTranlations } from "variables/projectTreeContentTranlations";
import { ProjectStructureButtons } from "objs/_ConfiguratorObjs/ProjectConfigurator/ProjectStructureButtons";
import { useProductOverview } from "hooks";

export const ProjectTree = Scrivito.connect(
  ({
    projectId,
    withDnd = false,
    pasteModeOnly = false,
    pasteItem = null,
    isWizardObjsOmit = false,
    withClipboard = false,
    withFiltering = false,
    isSummary = false,
    isModalView = false,
  }) => {
    const page = Scrivito.currentPage();
    const { lang } = useAppBaseContext();
    const { productConfiguratorObj } = useProductOverview({ page, lang });
    const [copiedItem, setCopiedItem] = useState(pasteItem);
    const [showProductPictures, setShowProductPictures] = useState(true);
    const [{ data }, exeGetProjectTree] = useQuery({
      query: ProjectDataQry,
      variables: { id: projectId },
    });

    useEffect(() => {
      setCopiedItem(pasteItem);
    }, [pasteItem]);

    const handleStartCopy = useCallback((itemData) => {
      setCopiedItem(itemData);
    }, []);

    const handleCopyCancel = useCallback(() => {
      setCopiedItem(null);
    }, []);

    const translations = projectTreeContentTranlations();

    if (!projectId) {
      return null;
    }

    const handleNavigateToProduct = useCallback(
      ({
        seriesId,
        foldedOrdercode,
        nodeId,
        orderCode,
        isWizardProduct,
        productId,
      }) => {
        if (isModalView) {
          return;
        }
        productConfiguratorObj &&
          Scrivito.navigateTo(() => productConfiguratorObj, {
            params: {
              seriesId: seriesId?.toString(),
              projectId,
              foldedOrdercode,
              nodeId,
              // TODO: temporary solution to pass order code in url, wait for AB implementation
              orderCode,
              isWizardProduct,
              productId,
              units: data?.project?.units,
            },
          });
      },
      [data?.project?.units, isModalView, productConfiguratorObj, projectId],
    );

    const context = useMemo(
      () => ({
        copiedItem,
        exeGetProjectTree,
        handleStartCopy,
        isWizardObjsOmit,
        pasteModeOnly,
        projectId,
        translations,
        withDnd,
        isSummary,
        isModalView,
        showProductPictures,
        setShowProductPictures,
        handleNavigateToProduct,
        lang,
        projectTree: data?.project?.projectTree,
        projectUnits: data?.project?.units,
      }),
      [
        copiedItem,
        exeGetProjectTree,
        handleStartCopy,
        isWizardObjsOmit,
        pasteModeOnly,
        projectId,
        translations,
        withDnd,
        isSummary,
        isModalView,
        showProductPictures,
        handleNavigateToProduct,
        lang,
        data?.project?.projectTree,
        data?.project?.units,
      ],
    );

    return (
      <ProjectTreeContextProvider value={context}>
        {data && (
          <>
            {withClipboard && copiedItem && !pasteModeOnly && (
              <Clipboard
                {...copiedItem}
                handleCopyCancel={handleCopyCancel}
                projectName={data.project?.title}
              />
            )}
            <ProjectStructureButtons
              withFiltering={withFiltering}
              showProductPictures={showProductPictures}
              setShowProductPictures={setShowProductPictures}
              isSummary={isSummary}
            />
            <DndProvider backend={HTML5Backend}>
              <ProjectTreeContent project={data?.project} />
            </DndProvider>
          </>
        )}
      </ProjectTreeContextProvider>
    );
  },
);
